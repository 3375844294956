<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="5">
            <v-combobox
                :items="nmos"
                :readonly="getNMOId!==null"
                :label="$t('lotNumberInventoryReport.nmoSelect.title')"
                persistent-placeholder
                v-model="selectedNmo"
                :loading="isNmoLoading"
                item-value="id"
                item-text="name"
                hide-details=""
                dense
            ></v-combobox>
          </v-col>
          <v-col>
            <v-combobox
                :items="[2022]"
                :label="$t('lotNumberInventoryReport.year')"
                persistent-placeholder
                v-model="selectedYear"
                dense
            ></v-combobox>
          </v-col>
          <v-col>
            <v-combobox
                :disabled="selectedYear==null"
                :items="validQuarter"
                :label="$t('lotNumberInventoryReport.quarter')"
                persistent-placeholder
                v-model="selectedQuarter"
                dense
            ></v-combobox>
          </v-col>
          <v-col cols="col-auto" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                :disabled="!form || !entries.length"
                @click="save"
            >
              {{ $t("actions.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="form">
          <v-data-table
              :headers="headers"
              :items="entries"
              :loading="isLotNumberAuditLoading"
              :options="{ itemsPerPage: -1 }"
              hide-default-footer
              dense
          >
            <template v-slot:item.lastAuditDate="{ item }">
              {{item.lastAuditDate | moment("YYYY-MM-DD")}}
            </template>
            <template v-slot:item.newShipments="{}">
              {{newShipmentsValue}}
            </template>
            <template v-slot:item.input="{ item }">
              <v-text-field
                  v-model.number="item.iuRemaining"
                  single-line
                  hide-details=""
                  class="arrowless-number-input"
                  outlined
                  dense
                  type="number"
                  :rules="[
                  (v) =>
                    (!!v && Number(v) >= 0) ||
                    $t('lotNumberInventoryReport.input.errors.lessThan0'),
                ]"
              ></v-text-field>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      form: null,
      selectedNmo: null,
      auditDate: null,
      newShipmentsValue: null,
      selectedQuarter: null,
      selectedYear: null,
      isLotNumberAuditLoading: false,
      isNmoLoading: false,
      entries: [],
      nmos: [],
    };
  },
  computed: {
    headers(){
      return [
        {
          text: this.$t("lotNumberInventoryReport.headers.lNumber"),
          class: "grey white--text",
          value: "lotNumber",
        },
        {
          text: this.$t("lotNumberInventoryReport.headers.lastReportDate"),
          class: "grey white--text",
          value: "lastAuditDate",
        },
        {
          text: this.$t("lotNumberInventoryReport.headers.newShipments"),
          class: "grey white--text",
          value: "newShipments",
          align: "center",
        },
        {
          text: this.$t("lotNumberInventoryReport.headers.lotStatus"),
          class: "grey white--text",
          value: "lotNumberStatus",
        },
        {
          text: this.$t("lotNumberInventoryReport.headers.estimatedRemaining"),
          class: "grey white--text",
          value: "estimatedIURemaining",
          width: "20%",
          align: "end",
        },
        {
          text: this.$t("lotNumberInventoryReport.headers.input"),
          class: "grey white--text",
          value: "input",
          width: "20%",
        },
      ]
    },
    getIsAdmin() {
      return this.$store.getters.getIsAdmin
    },
    getNMOId() {
      return this.$store.getters.getNMOId
    },
    isReadyToLoad() {
      return this.selectedNmo !== null && this.selectedYear !== null && this.selectedQuarter !== null
    },
    validQuarter() {
      let quarters = [1, 2, 3, 4]
      if (this.selectedYear === this.$moment().year()) {
        if (this.$moment().month() > 9) {
          return [1, 2, 3]
        } else if (this.$moment().month() > 6) {
          return [1, 2]
        } else {
          return [1]
        }
      }
      return quarters
    }

  },
  watch: {
    selectedNmo() {
      if (this.isReadyToLoad) this.loadLotToAudit()
    },
    selectedYear() {
      this.selectedQuarter = null
      if (this.isReadyToLoad) this.loadLotToAudit()
    },
    selectedQuarter() {
      if (this.isReadyToLoad) this.loadLotToAudit()
    },
  },
  methods: {
    async loadLotToAudit() {
      try {
        this.isLotNumberAuditLoading = this.$store.state.loadingBarColor;
        const response = await axios.get(this.$store.getters.getEnvironment + `LotInventoryAudit`, {
          params: {
            nmoId: this.selectedNmo.id,
            year: this.selectedYear,
            quarter: this.selectedQuarter
          }
        })
        this.isLotNumberAuditLoading = false;
        this.entries = response.data.entries
        this.auditDate = response.data.auditDate
        this.newShipmentsValue = response.data.newShipments
      } catch (e) {
        console.log(e)
      }


    },
    loadNMOs() {
      this.isNmoLoading = this.$store.state.loadingBarColor;
      axios
          .get(this.$store.getters.getEnvironment + "nmo")
          .then((res) => {
            this.nmos = res.data;
            if (this.getNMOId !== null) {
              this.selectedNmo = this.nmos.filter((item) => item.id === this.getNMOId)[0]
            }
          })
          .finally(() => {
            this.isNmoLoading = false;
          });
    },
    async save() {
      try {
        let payload = {
          nmoId: this.selectedNmo.id,
          auditDate: this.auditDate,
          newShipments: this.newShipmentsValue,
          entries: this.entries.map(item => ({
            estimatedIURemaining: item.estimatedIURemaining,
            iuRemaining: item.iuRemaining,
            lotNumberCountryId: item.lotNumberCountryId,
            lastAuditDate: item.lastAuditDate
          }))
        }
        const response = await axios.post(this.$store.getters.getEnvironment + `LotInventoryAudit`, payload)
        console.log(response.data.message)
      } catch (e) {
        console.log(e)
      }
    }
    ,
  },
  created() {
    this.loadNMOs();
  }
  ,
}
;
</script>

<style scoped>
.arrowless-number-input >>> input[type="number"] {
  -moz-appearance: textfield;
}

.arrowless-number-input >>> input::-webkit-outer-spin-button,
.arrowless-number-input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>