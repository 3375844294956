 <template>
  <div>
    <v-card tile elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="3" class="d-flex">
            <v-text-field
              v-model="searchFilter"
              :label="$t('actions.filters')"
              append-icon="mdi-magnify"
              hide-details=""
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="d-flex">
            <v-select
              :items="activeStatuses"
              :label="$t('patient.headerRow.status')"
              item-text="text"
              item-value="value"
              v-model="activeFilters.status"
              @change="load()"
              hide-details=""
              dense
            ></v-select>
          </v-col>
          <v-col cols="1" class="d-flex">
            <v-btn color="blue" text small class="white--text" @click="load()"
              ><v-icon>mdi-refresh</v-icon>{{ $t("actions.refresh") }}</v-btn
            >
          </v-col>
          <v-col class="d-flex">
            <v-spacer></v-spacer>
            <v-btn small text tile class="green--text" @click="openDetail(-1)">
              <v-icon>mdi-plus</v-icon> {{ $t("actions.add") }}</v-btn
            >
            <v-menu open-on-hover offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  title
                  class="green--text"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in actions" :key="index">
                  <v-list-item-title @click="item.action">
                    <v-btn tile text class="green--text menu-button">
                      <v-icon v-if="item.icon != null">{{ item.icon }}</v-icon>
                      {{ item.title }}</v-btn
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :key="this.$store.state.patientKey"
          :items="datas"
          :sort-by="['name']"
          :loading="isDataLoading"
          :loading-text="$t('app.loading')"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
          }"
          :items-per-page="this.$store.state.itemsPerPage"
          :search="search"
          dense
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="openDetail(item.id)" icon>
              <v-icon class="pa-3"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.nmoCountryCode="{ item }">
            <span v-if="item.nmoCountryCode != null">{{
              $t("country." + item.nmoCountryCode)
            }}</span>
          </template>
          <template v-slot:item.diagnosisName="{ item }">
            <span v-if="item.diagnosisName != null">{{
              $t("patient.diagnosis." + item.diagnosisName)
            }}</span>
          </template>
          <template v-slot:item.share="{ item }">
            <v-checkbox v-model="item.share" readonly disabled></v-checkbox>
          </template>
          <template v-slot:item.dateOfBirth="{ item }">
            <span v-if="item.dateOfBirth != null">
              {{ item.dateOfBirth.substring(0, 10) | moment("YYYY-MM-DD") }}
            </span>
          </template>
          <template v-slot:item.creationDate="{ item }">
            <span v-if="item.creationDate != null">
              {{ item.creationDate | moment("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span v-if="item.updatedAt != null">
              {{ item.updatedAt | moment("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </template>
          <template v-slot:item.isActive="{ item }">
            {{ item.isActive ? $t("app.yes") : $t("app.no") }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template> 

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      isDataLoading: false,
      datas: [],
      activeStatuses: [
        { text: this.$t("patient.filters.status.active"), value: true },
        {
          text: this.$t("patient.filters.status.inactive"),
          value: false,
        },
        { text: this.$t("patient.filters.status.all"), value: "all" },
      ],
      activeFilters: { status: true },
      actions: [
        {
          title: this.$t("actions.downloadTemplate", {
            template: this.$t("app.navBar.patients"),
          }),
          action: () => {
            this.downloadTemplate();
          },
          icon: "mdi-download",
          //   icon: "mdi-",
        },
        {
          title: this.$t("actions.import", {
            template: this.$t("app.navBar.patients"),
          }),
          action: () => {
            this.importPatient();
          },
          icon: "mdi-plus",
        },
        {
          title: this.$t("actions.exportTemplate", {
            template: this.$t("app.navBar.patients"),
          }),
          action: () => {
            this.exportPatients();
          },
          icon: "mdi-export",
        },
      ],
      search: "",
      timeout: undefined,
      isActive: true,
      headers: [
        {
          text: this.$t("patient.headerRow.action"),
          value: "action",
          class: "grey white--text",
          sortable: false,
        },
        {
          text: this.$t("patient.headerRow.nmoName"),
          value: "nmoName",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.nmoCountry"),
          value: "nmoCountryCode",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.htcName"),
          value: "htcName",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.patientId"),
          value: "patientId",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.wbdrId"),
          value: "wbdrId",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.dateOfBirth"),
          value: "dateOfBirth",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.diagnosis"),
          value: "diagnosisName",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.physician"),
          value: "physician",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.createdBy"),
          value: "createdByName",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.creationDate"),
          value: "creationDate",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.updatedBy"),
          value: "updatedByName",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.updateDate"),
          value: "updatedAt",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.status"),
          value: "isActive",
          class: "grey white--text",
        },
      ],
    };
  },
  computed: {
    refreshFlag() {
      return this.$store.state.patientKey;
    },
    searchFilter: {
      get() {
        return this.search;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.search = val;
        }, 500);
      },
    },
    activeFiltersStatus() {
      var status = this.activeFilters.status;
      if (status === "all") return null;
      return status;
    },
  },
  watch: {
    refreshFlag() {
      this.load();
    },
  },
  methods: {
    load() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      var params = {
        isActive:
          this.activeFilters.status == "all" ? null : this.activeFilters.status,
      };
      axios
        .get(this.$store.getters.getEnvironment + "patient", { params })
        .then((response) => {
          this.datas = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    openDetail(value) {
      this.$store.commit("showModal", {
        componentName: "PatientDetail",
        parameter: { id: value },
      });
    },
    importPatient() {
      this.$store.commit("showModal", {
        componentName: "ImportPatient",
      });
    },
    download(blob, filename, applicationType) {
      blob = new Blob([blob], { type: applicationType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    downloadTemplate() {
      this.$store.commit("showModal", {
        componentName: "DownloadTemplate",
        parameter: {
          title: "dialogs.title",
          openFileTitle: "dialogs.openFileTitle",
          openFileText: "dialogs.openFileText",
          saveFileTitle: "dialogs.saveFileTitle",
          saveFileText: "dialogs.saveFileText",
          moreInfo: "dialogs.moreInfo",
        },
      });
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}patient/importtemplate`
      );
      return xhr
        .then((res) => {
          this.download(
            res.data,
            "patient_import_template.csv",
            "application/csv"
          );
        })
        .catch((e) => {});
    },
    exportPatients() {
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}patient/export`,
        { responseType: "blob" }
      );

      xhr
        .then((res) => {
          this.download(
            res.data,
            "patients.xlsx",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          //this.download(res.data, "patients.csv", "application/csv");
        })
        .catch((e) => {});
    },
    convertUTC(d) {
      var s = d.format("YYYY-MM-DD");
      return s;
    },
  },
  created() {
    this.load();
  },
};
</script>

