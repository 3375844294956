<template>
  <v-form ref="form" @keyup.native.enter="login()" v-model="isValid">
    <v-card class="mx-auto" max-width="500" elevation="0">
      <v-card-title>
        <v-alert
          v-if="showWrongCredentialsAlert"
          type="error"
          width="100%"
          class="mx-4"
          dense
          >{{ $t("signin.wrongUsernamePassword") }}</v-alert
        >
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="$t('signin.email')"
          required
          v-model="userConnection.email"
          :rules="[mandatoryRule]"
          outlined
        ></v-text-field>
        <v-text-field
          :label="$t('signin.password')"
          v-model="userConnection.password"
          type="password"
          required
          outlined
          :rules="[mandatoryRule]"
        ></v-text-field>
        <v-card-text>
          <ForgotPassword></ForgotPassword>
        </v-card-text>
      </v-card-text>
      <v-card-actions class="justify-center">
        <!-- <v-btn text @click="create()" :disabled="isSending">Create</v-btn> -->
        <v-btn text @click="login()" :disabled="isSending">
          {{ $t("signin.signin") }}
        </v-btn>
        <v-progress-circular
          v-if="isSending"
          indeterminate
          :color="this.$store.state.loadingBarColor"
        ></v-progress-circular>
        <v-row
          align="center"
          justify="center"
          v-if="isQuickLoginAvailable === true"
        >
          <v-col cols="12">
            <v-btn-toggle>
              <v-btn
                text
                @click="
                  login({ email: 'admin@wfh.org', password: 'aaaaaaaa!' })
                "
                >Admin</v-btn
              >
              <v-btn
                text
                @click="
                  login({ email: 'nmo@admin.org', password: 'aaaaaaaa!' })
                "
                >NMO Admin</v-btn
              >
              <v-btn
                text
                @click="
                  login({
                    email: 'Doctor@admin.org',
                    password: 'aaaaaaaa!',
                  })
                "
                >Doctor</v-btn
              >
              <v-btn
                text
                @click="
                  login({ email: 'staff@admin.org', password: 'aaaaaaaa!' })
                "
                >Staff</v-btn
              >
              <v-btn
                text
                @click="
                  login({
                    email: 'volunteerhtc@admin.org',
                    password: 'aaaaaaaa!',
                  })
                "
                >VolunteerHTC</v-btn
              >
              <v-btn
                text
                @click="
                  login({
                    email: 'WFHVolunteerNMO@WFHVolunteer-NMO.WFHVolunteer-NMO',
                    password: 'aaaaaaaa!',
                  })
                "
                >VolunteerNMO</v-btn
              >
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from "axios";
import ForgotPassword from "./ForgotPassword.vue";

export default {
  data() {
    return {
      isValid: false,
      isSending: false,
      showWrongCredentialsAlert: false,
      userConnection: {
        password: "",
        email: "",
      },
      mandatoryRule: (v) => !!v || this.$t("signin.signInErrorMEssage"),
    };
  },
  components: {
    ForgotPassword,
  },
  computed: {
    isQuickLoginAvailable() {
      console.log(this.$store.getters.getIsQuickLoginAvailable);
      return this.$store.getters.getIsQuickLoginAvailable;
    },
  },
  methods: {
    login(connectionInfo) {
      if (this.$refs.form.validate()) {
        this.isSending = true;
        connectionInfo = Object.assign(
          {
            email: this.userConnection.email,
            password: this.userConnection.password,
          },
          connectionInfo
        );
        axios
          .post(
            this.$store.getters.getEnvironment + "auth/login/",
            connectionInfo
          )
          .then((response) => {
            //sessionStorage.setItem("userData", JSON.stringify(response.data));
            sessionStorage.setItem("token", response.data.token);
            var id = response.data.id;
            var name = response.data.name;
            var guid = response.data.guid;
            var email = response.data.email;
            var role = response.data.role;
            var nmoId = response.data.nmoId;
            var htcGroupId = response.data.htcGroupId;
            var htcId = response.data.htcId;
            var lang = response.data.language;
            var canDeleteUtilization = response.data.canDeleteUtilization;

            this.$store.commit("setConnectedUser", {
              id,
              name,
              guid,
              email,
              role,
              nmoId,
              htcGroupId,
              htcId,
              lang,
              canDeleteUtilization,
            });

            this.$i18n.locale = response.data.language;
            this.isSending = false;
          })
          .then(() => {
            this.$router.push({ name: "account" });
          })
          .catch((e) => {
            this.isSending = false;
            this.showWrongCredentialsAlert = true;
          });
      }
    },
    create() {
      this.isSending = this.$store.state.loadingButtonColor;
      axios
        .post(this.$store.getters.getEnvironment + "auth/createuser/", {})
        .then((response) => {
          this.isSending = false;
        })
        .catch((e) => {
          this.isSending = false;
          this.isPasswordOK = false;
        });
    },
  },
  created() {
    this.$i18n.locale = "en";
  },
};
</script>