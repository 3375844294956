<template>
  <div>
    <v-card tile elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="12" lg="9" md="8" sm="12" class="d-flex">
            <v-row>
              <v-col cols="4" sm="2" md="1">
                <h5 style="min-width:60px; max-width:60px">
                  {{ $t("actions.filters") }}
                </h5>
              </v-col>
              <v-col cols="8" sm="10" md="11">
                <v-row class="pb-2">
                  <v-col cols="12" md="6" lg="4" xl="2" sm="6" class="pb-1">
                    <v-text-field
                      v-model="htcSearchFilter"
                      :label="$t('htc.headerRow.htcName')"
                      append-icon="mdi-magnify"
                      hide-details=""
                      dense
                      class="pr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="2" sm="6" class="pb-1">
                    <v-text-field
                      v-model="patientIdSearchFilter"
                      :label="$t('patient.headerRow.patientId')"
                      append-icon="mdi-magnify"
                      hide-details=""
                      dense
                      class="pr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="2" sm="6" class="pb-1">
                    <v-text-field
                      v-model="lotNumberSearchFilter"
                      :label="$t('lotNumberInventoryReport.headers.lNumber')"
                      append-icon="mdi-magnify"
                      hide-details=""
                      dense
                      class="pr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="2" sm="6" class="pb-1">
                    <v-text-field
                      v-model="searchFilter"
                      :label="$t('utilization.headerRow.otherColumns')"
                      append-icon="mdi-magnify"
                      hide-details=""
                      dense
                      class="pr-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="2" sm="6" class="pb-1">
                    <v-menu
                      ref="menuFrom"
                      v-model="isDatePickerFromOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      hide-details=""
                      dense
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          hide-details=""
                          append-icon="mdi-magnify"
                          v-model="treatmentDateFromSearchFilter"
                          :label="$t('utilization.headerRow.treatmentDateFrom')"
                          class="pr-2"
                          readonly
                          clearable
                          v-on="on"
                          v-bind="attrs"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        dense
                        hide-details=""
                        ref="picker"
                        style="width: 100%;"
                        show-adjacent-months
                        v-model="treatmentDateFromSearchFilter"
                        @input="isDatePickerFromOpen = false"
                        :max="new Date(Date.now()).toISOString().substr(0, 10)"
                        min="1963-01-01"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="2" sm="6" class="pb-1">
                    <v-menu
                      ref="menuTo"
                      v-model="isDatePickerToOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      hide-details=""
                      dense
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          hide-details=""
                          append-icon="mdi-magnify"
                          v-model="treatmentDateToSearchFilter"
                          :label="$t('utilization.headerRow.treatmentDateTo')"
                          readonly
                          clearable
                          class="pr-2"
                          v-on="on"
                          v-bind="attrs"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        dense
                        hide-details=""
                        ref="picker"
                        show-adjacent-months
                        v-model="treatmentDateToSearchFilter"
                        @input="isDatePickerToOpen = false"
                        :max="new Date(Date.now()).toISOString().substr(0, 10)"
                        min="1963-01-01"
                        style="width: 100%;"
                      ></v-date-picker> </v-menu
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="1" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              color="blue"
              text
              tile
              small
              class="black--text"
              @click="load()"
            >
              <v-icon>mdi-refresh</v-icon>
              {{ $t("actions.refresh") }}
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="12" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn text tile small class="green--text" @click="openDetail(-1)">
              <v-icon>mdi-plus</v-icon>
              {{ $t("actions.add") }}
            </v-btn>
            <v-menu open-on-hover offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  title
                  class="green--text"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      text
                      tile
                      class="green--text menu-button"
                      @click="downloadTemplate()"
                    >
                      <v-icon>mdi-download</v-icon>
                      {{
                        $t("actions.downloadTemplate", {
                          template: $t("app.navBar.utilization"),
                        })
                      }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      text
                      tile
                      class="green--text menu-button"
                      @click="importUtilization()"
                    >
                      <v-icon>mdi-plus</v-icon>
                      {{ $t("actions.import") }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      text
                      tile
                      class="green--text menu-button"
                      @click="exportUtilization('xlsx')"
                    >
                      <v-icon>mdi-export</v-icon>
                      {{ $t("actions.export") }} ( XLSX )
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      text
                      tile
                      class="green--text menu-button"
                      @click="exportUtilization('csv')"
                    >
                      <v-icon>mdi-export</v-icon>
                      {{ $t("actions.export") }} ( CSV )
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :key="this.$store.state.utilizationKey"
          :items="datas"
          :loading="isDataLoading"
          :loading-text="$t('app.loading')"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
          }"
          :items-per-page="this.$store.state.itemsPerPage"
          :search="search"
          :options.sync="options"
          :server-items-length="totalRecords"
          dense
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="openDetail(item.id)" icon>
              <v-icon class="pa-2">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.nmoCountryCode="{ item }">
            <span v-if="item.nmoCountryCode != null">
              {{ $t("country." + item.nmoCountryCode) }}
            </span>
          </template>
          <template v-slot:item.patientDiagnosis="{ item }">{{
            $t(`patient.diagnosis.${item.patientDiagnosis}`)
          }}</template>
          <template v-slot:item.inhibitors="{ item }">
            <span>
              {{
                item.inhibitors
                  ? $t("utilization.inhibitors.1")
                  : $t("utilization.inhibitors.0")
              }}
            </span>
          </template>
          <template v-slot:item.homeTreatment="{ item }">
            <span>
              {{
                item.homeTreatment
                  ? $t("utilization.homeTreatment.1")
                  : $t("utilization.homeTreatment.0")
              }}
            </span>
          </template>
          <template v-slot:item.treatmentType="{ item }">
            {{ $t(`utilization.treatmentTypes.${item.treatmentType}`) }}
          </template>
          <template v-slot:item.treatmentDescription="{ item }">
            {{ item.treatmentDescription }}
          </template>
          <template v-slot:item.treatmentDate="{ item }">{{
            item.treatmentDate | moment("YYYY-MM-DD HH:mm:ss")
          }}</template>
          <template v-slot:item.dateOfBirth="{ item }">{{
            item.dateOfBirth | moment("YYYY-MM-DD")
          }}</template>
          <template v-slot:item.creationDate="{ item }">
            <span v-if="item.creationDate != null">
              {{ item.creationDate | moment("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span v-if="item.updatedAt != null">{{
              item.updatedAt | moment("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <a ref="download" target="_blank" style="display: none"></a>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      datas: [],
      totalRecords: 0,
      isDatePickerFromOpen: false,
      isDatePickerToOpen: false,
      options: {
        page: 1,
        sortBy: ["treatmentDate"],
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        sortDesc: [true],
      },
      headers: [
        {
          text: this.$t("utilization.headerRow.action"),
          value: "action",
          class: "grey white--text",
          sortable: false,
        },
        {
          text: this.$t("utilization.headerRow.nmoName"),
          value: "nmoName",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.nmoCountry"),
          value: "nmoCountry",
          class: "grey white--text",
        },
        // {
        //   text: this.$t("utilization.headerRow.nmoRegion"),
        //   value: "nmoRegion",
        //   class: "grey white--text",
        // },
        {
          text: this.$t("utilization.headerRow.htcName"),
          value: "htcName",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.patientId"),
          value: "patientId",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.wbdrId"),
          value: "wbdrId",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.patientDiagnosis"),
          value: "patientDiagnosis",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.patientDateOfBirth"),
          value: "dateOfBirth",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.patientPhysician"),
          value: "physician",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.treatmentDate"),
          value: "treatmentDate",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.treatmentType"),
          value: "treatmentType",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.treatmentDescription"),
          value: "treatmentDescription",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.inhibitors"),
          value: "inhibitors",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.homeTreatment"),
          value: "homeTreatment",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.productName"),
          value: "productName",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.productManufacturer"),
          value: "productManufacturer",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.lotNumber"),
          value: "lotNumber",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.productPotency"),
          value: "potency",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.totalUIUsed"),
          value: "totalUIUsed",
          class: "grey white--text",
        },
        // {
        //   text: this.$t("utilization.headerRow.productProjectType"),
        //   value: "productProjectTypeName",
        //   class: "grey white--text",
        // },
        {
          text: this.$t("utilization.headerRow.creationDate"),
          value: "creationDate",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.createdBy"),
          value: "createdBy",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.updatedBy"),
          value: "updatedBy",
          class: "grey white--text",
        },
        {
          text: this.$t("utilization.headerRow.updateDate"),
          value: "updatedAt",
          class: "grey white--text",
        },
      ],
      isDataLoading: false,
      htcSearch: "",
      patientIdSearch: "",
      lotNumberSearch: "",
      treatmentDateFromSearch: "",
      treatmentDateToSearch: "",
      search: "",
      timeout: undefined,
    };
  },
  computed: {
    refreshFlag() {
      return this.$store.state.patientKey;
    },
    htcSearchFilter: {
      get() {
        return this.htcSearch;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.htcSearch != val) this.htcSearch = val;
        }, 1000);
      },
    },
    patientIdSearchFilter: {
      get() {
        return this.patientIdSearch;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.patientIdSearch != val) this.patientIdSearch = val;
        }, 1000);
      },
    },
    lotNumberSearchFilter: {
      get() {
        return this.lotNumberSearch;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.lotNumberSearch != val) this.lotNumberSearch = val;
        }, 1000);
      },
    },
    treatmentDateFromSearchFilter: {
      get() {
        return this.treatmentDateFromSearch;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.treatmentDateFromSearch != val)
            this.treatmentDateFromSearch = val;
        }, 10);
      },
    },
    treatmentDateToSearchFilter: {
      get() {
        return this.treatmentDateToSearch;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.treatmentDateToSearch != val)
            this.treatmentDateToSearch = val;
        }, 10);
      },
    },
    searchFilter: {
      get() {
        return this.search;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.search != val) this.search = val;
        }, 1000);
      },
    },
  },
  watch: {
    options: {
      handler(args) {
        this.options = args;
        this.load();
      },
      deep: true,
    },
    search: {
      handler(args) {
        this.setSearch();
      },
    },
    htcSearch: {
      handler(args) {
        this.setSearch();
      },
    },
    patientIdSearch: {
      handler(args) {
        this.setSearch();
      },
    },
    lotNumberSearch: {
      handler(args) {
        this.setSearch();
      },
    },
    treatmentDateFromSearch: {
      handler(args) {
        this.setSearch();
      },
    },
    treatmentDateToSearch: {
      handler(args) {
        this.setSearch();
      },
    },
    refreshFlag() {
      this.load();
    },
  },
  methods: {
    setSearch() {
      this.options.page = 1;
      this.load();
    },

    importMproUtilization() {
      axios
        .get(`${this.$store.getters.getEnvironment}utilization/sync`)
        .then((res) => {
          if (res.status !== 200) alert("Error");
        })
        .catch((e) => console.error(e));
    },
    load() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let sortOrder = null;

      console.log(sortDesc);
      if (sortBy.length > 0) sortBy = sortBy[0];
      if (sortDesc.length > 0) sortOrder = sortDesc[0] ? "DESC" : "ASC";

      const filters = this.buildPayload();
      axios
        .post(
          this.$store.getters.getEnvironment + "utilization/search",
          filters,
          { params: { page, size: itemsPerPage, sortBy, sortOrder } }
        )
        .then((response) => {
          this.datas = response.data.records;
          console.log(this.datas);
          this.totalRecords = response.data.totalCount;

          this.isDataLoading = false;
        })
        .catch((e) => {
          this.isDataLoading = false;
        });
    },
    buildPayload() {
      const filters = {};

      if (this.search !== "") filters.otherFilter = this.search;
      if (this.htcSearch !== "") filters.htcFilter = this.htcSearch;
      if (this.patientIdSearch !== "")
        filters.patientIdFilter = this.patientIdSearch;
      if (this.lotNumberSearch !== "")
        filters.lotNumberFilter = this.lotNumberSearch;
      if (this.treatmentDateFromSearch !== "")
        filters.TreatmentDateFrom = this.treatmentDateFromSearch;
      if (this.treatmentDateToSearch !== "")
        filters.TreatmentDateTo = this.treatmentDateToSearch;

      return filters;
    },
    openDetail(value) {
      this.$store.commit("showModal", {
        componentName: "UtilizationDetail",
        parameter: { id: value },
      });
    },
    importUtilization() {
      this.$store.commit("showModal", {
        componentName: "ImportUtilization",
      });
    },
    download(blob, filename, applicationType) {
      blob = new Blob([blob], { type: applicationType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    exportUtilization(fileType) {
      const filters = this.buildPayload();

      var xhr = axios.post(
        `${this.$store.getters.getEnvironment}utilization/export?fileType=${fileType}`,
        filters,
        { responseType: "blob" }
      );

      return xhr
        .then((res) => {
          this.download(
            res.data,
            "utilization." + fileType,
            res.headers["content-type"] //"application/csv"
          );
        })
        .catch((e) => {});
    },
    downloadTemplate() {
      this.$store.commit("showModal", {
        componentName: "DownloadTemplate",
        parameter: {
          title: "dialogs.title",
          openFileTitle: "dialogs.openFileTitle",
          openFileText: "dialogs.openFileText",
          saveFileTitle: "dialogs.saveFileTitle",
          saveFileText: "dialogs.saveFileText",
          moreInfo: "dialogs.moreInfo",
        },
      });
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}utilization/importtemplate`
      );
      return xhr
        .then((res) => {
          this.download(
            res.data,
            "utilization_import_template.csv",
            "application/csv"
          );
        })
        .catch((e) => {});
    },
    formatDate(val) {
      return this.$moment
        .parseZone(val)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {},
};
</script>
