import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Navbar from "../views/Navbar.vue";
import Account from "../components/account/Account.vue";
import HTCS from "../components/account/HTCS.vue";
import NMOS from "../components/account/NMOS.vue";
import Patients from "../components/account/Patients.vue";
import LotNumbers from "../components/account/LotNumbers.vue";
import Products from "../components/account/Products.vue";
import Users from "../components/account/Users.vue";
import Utilization from "../components/account/Utilization.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ChangePassword from "../components/account/ChangePassword.vue";
import ControlPanel from "../components/account/ControlPanel.vue";
import LotNumberInventoryAudit from "../components/account/LotNumberInventoryAudit.vue";
import store from "../store";

import NavbarAuthentificated from "../components/account/Navbar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    components: {
      default: Login,
      navigation: Navbar,
    },
  },
  {
    path: "/resetpassword/:enc",
    components: {
      default: ResetPassword,
      navigation: Navbar,
    },
    props: {
      default: true,
      navigation: (route) => ({ search: route.query.q }),
    },
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    components: {
      default: ChangePassword,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/account",
    name: "account",
    components: {
      default: Account,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/htcs",
    name: "HTCS",
    components: {
      default: HTCS,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters.getIsAdmin || store.getters.getIsNMOAdmin) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/nmos",
    name: "NMOS",
    components: {
      default: NMOS,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters.getIsAdmin || store.getters.getIsNMOAdmin) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/patients",
    name: "Patients",
    components: {
      default: Patients,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/lots",
    name: "LotNumbers",
    components: {
      default: LotNumbers,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/products",
    name: "Products",
    components: {
      default: Products,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/inventoryAudit",
    name: "InventoryAudit",
    components: {
      default: LotNumberInventoryAudit,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters.getIsAdmin || store.getters.getIsNMOAdmin) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/users",
    name: "Users",
    components: {
      default: Users,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters.getIsAdmin || store.getters.getIsNMOAdmin) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/control-panel",
    name: "ControlPanel",
    components: {
      default: ControlPanel,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters.getIsAdmin) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/utilization",
    name: "Utilization",
    components: {
      default: Utilization,
      navigation: NavbarAuthentificated,
    },
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
