var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.$t('actions.filters'),"append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue","text":"","small":""},on:{"click":function($event){return _vm.load()}}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t("actions.refresh"))+" ")],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-spacer'),_c('v-btn',{staticClass:"green--text",attrs:{"text":"","tile":"","small":""},on:{"click":function($event){return _vm.openDetail(-1)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("actions.add"))+" ")],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{key:this.$store.state.userKey,attrs:{"headers":_vm.headers,"items":_vm.datas,"sort-by":['name'],"loading":_vm.isDataLoading,"loading-text":_vm.$t('app.loading'),"footer-props":{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] },"items-per-page":this.$store.state.itemsPerPage,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.openDetail(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.nmoCountryCode",fn:function(ref){
var item = ref.item;
return [(item.nmoCountryCode != null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("country." + item.nmoCountryCode))+" ")]):_vm._e()]}},{key:"item.share",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":"","disabled":""},model:{value:(item.share),callback:function ($$v) {_vm.$set(item, "share", $$v)},expression:"item.share"}})]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [(item.creationDate != null)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.creationDate,"YYYY-MM-DD HH:mm:ss")))]):_vm._e()]}},{key:"item.activationDate",fn:function(ref){
var item = ref.item;
return [(item.activationDate != null)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.activationDate,"YYYY-MM-DD HH:mm:ss")))]):_vm._e()]}},{key:"item.hasMobileAccess",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"tile":"","icon":""},on:{"click":function($event){return _vm.updateUserPartially({
                id: item.id,
                hasMobileAccess: !item.hasMobileAccess,
              })}}},[_c('v-icon',{attrs:{"dense":"","color":item.hasMobileAccess ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.hasMobileAccess ? "mdi-check" : "mdi-cancel")+" ")])],1)]}},{key:"item.isActive",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"tile":"","icon":""},on:{"click":function($event){return _vm.updateUserPartially({ id: item.id, isActive: !item.isActive })}}},[_c('v-icon',{attrs:{"dense":"","color":item.isActive ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.isActive ? "mdi-check" : "mdi-cancel")+" ")])],1)]}},{key:"item.canDeleteUtilization",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"tile":"","icon":""},on:{"click":function($event){return _vm.updateUserPartially({
                id: item.id,
                canDeleteUtilization: !item.canDeleteUtilization,
              })}}},[_c('v-icon',{attrs:{"dense":"","color":item.canDeleteUtilization ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.canDeleteUtilization ? "mdi-check" : "mdi-cancel")+" ")])],1)]}}])})],1)],1),_c('v-snackbar',{attrs:{"light":"","color":"green"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("X")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t("user.message.updated"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }