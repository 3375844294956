<template>
  <v-form ref="form" @keyup.native.enter="reset()" v-model="valid">
    <v-card class="mx-auto pt-10" max-width="500" elevation="0">
      <v-card-text>
        <v-text-field
          dense
          hide-details=""
          class="pb-2"
          :label="$t('changePassword.oldPassword')"
          v-model="userResetPassword.oldPassword"
          type="password"
          :rules="[getIsOldPasswordOK]"
          required
          outlined
        ></v-text-field>
        <v-text-field
          dense
          hide-details=""
          class="pb-2"
          :label="$t('changePassword.newPassword')"
          v-model="userResetPassword.newPassword"
          type="password"
          required
          outlined
          :rules="passwordRules"
        ></v-text-field>
        <v-text-field
          dense
          hide-details=""
          class="pb-2"
          :label="$t('changePassword.confirmPassword')"
          v-model="userResetPassword.passwordConfirmation"
          type="password"
          required
          outlined
          :rules="passwordConfirmationRules"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="reset" text :disabled="isSending || !valid">{{
          $t("changePassword.confirmButton")
        }}</v-btn>
        <v-progress-circular
          v-if="isSending"
          indeterminate
          color="#0071c5"
        ></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  props: ["parameter"],
  data() {
    return {
      valid: false,
      isOldPasswordOK: true,
      userResetPassword: {
        userId: this.$store.state.connectedUser.id,
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: "",
      },
      isSending: false,
      passwordRules: [
        (v) =>
          (v && v.length >= 8) || this.$t("changePassword.passwordCharacters"),
        (v) => {
          if (v.length > 0) {
            const pattern = /^(?=.*[!-\/:-@\[-`{-~]).{8,}$/i; //eslint-disable-line
            return pattern.test(v) || this.$t("changePassword.passwordSpecial");
          } else {
            return this.$t("changePassword.requiredPassword");
          }
        },
      ],
      passwordConfirmationRules: [
        (v) => !!v || this.$t("changePassword.requiredPassword"),
        (v) =>
          (v && v.length >= 7) || this.$t("changePassword.passwordCharacters"),
        (v) =>
          (v && v === this.userResetPassword.newPassword) ||
          this.$t("changePassword.matchingPassword"),
        (v) => {
          if (v.length > 0) {
            const pattern = /^(?=.*[!-\/:-@\[-`{-~]).{8,}$/i; //eslint-disable-line
            return pattern.test(v) || this.$t("changePassword.passwordSpecial");
          } else {
            return this.$t("changePassword.requiredPassword");
          }
        },
      ],
    };
  },
  mounted: function () {
    this.dialog = true;
  },
  methods: {
    reset() {
      if (this.$refs.form.validate()) {
        this.isSending = true;
        axios
          .post(
            this.$store.getters.getEnvironment + "user/resetpassword/",
            this.userResetPassword
          )
          .then(() => {
            this.isSending = false;
            this.showSnackbar = true;
            this.userResetPassword.oldPassword = "";
            this.userResetPassword.newPassword = "";
            this.userResetPassword.passwordConfirmation = "";
            this.$store.commit("showMessageOK", this.$t("app.saved"));
            this.$router.push({ name: "account" });
          })
          .catch((error) => {
            this.isSending = false;
            if (error.response.data.data == "0") this.isOldPasswordOK = false;
          });
      }
    },
    close() {
      this.$router.push({ name: "Login" });
    },
  },
  computed: {
    getIsOldPasswordOK() {
      if (this.isOldPasswordOK) return true;
      else return this.$t("changePassword.oldPasswordDoNotMatch");
    },
  },
};
</script>
