import Vue from 'vue'
import axios from "axios";
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import i18n from './i18n';
import momentTimezone from 'moment-timezone'
import jwt_decode from 'jwt-decode'
import "@/sass/app.scss";

Vue.config.productionTip = false
Vue.use(require('vue-moment'), {
    momentTimezone
});

export default axios.create({
    timeout: 1800000
});

axios.interceptors.request.use(
    function (config) {
        const token = sessionStorage.getItem("token");
        if (token != null) {
            config.headers.Authorization =
                "bearer " + sessionStorage.getItem("token");
        }

        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

// axios.interceptors.response.

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        // if token exists AND is valid
        var token = sessionStorage.getItem('token');
        if ((token && jwtDecode(token).exp * 1000 > Date.now())) {

            // does not have the user in store (on refresh)
            if (!(store.state.connectedUser && store.state.connectedUser.role)) {
                var xhr = axios.get(`${store.getters.getEnvironment}auth/silentAuth`)
                await xhr.then((response) => {
                    //sessionStorage.setItem("userData", JSON.stringify(response.data));
                    //sessionStorage.setItem("token", response.data.token);
                    //var obj = { ...response.data }
                    var id = response.data.id;
                    var name = response.data.name;
                    var guid = response.data.guid;
                    var email = response.data.email;
                    var role = response.data.role;
                    var nmoId = response.data.nmoId;
                    var htcGroupId = response.data.htcGroupId;
                    var htcId = response.data.htcId;
                    var lang = response.data.language;
                    var canDeleteUtilization = response.data.canDeleteUtilization;

                    store.commit("setConnectedUser", {
                        id,
                        name,
                        guid,
                        email,
                        role,
                        nmoId,
                        htcGroupId,
                        htcId,
                        lang,
                        canDeleteUtilization
                    });
                    this.$i18n.locale = response.data.language;
                    //next();
                }).catch(e => {})

                next()
            } else {
                next()
            }
        } else {

            next({
                name: 'Login'
            })

        }
    } else {
        next()
    }
})
// else
// else {
//     // go
//     
//     next()
// }


// if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!sessionStorage.getItem("token")) {
//         next({
//             name: 'Login'
//         })
//     } else {
//         next()
//     }
// } else {
//     next()
// }

//})

new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')


Vue.component('NMODetail', () => import('./components/account/dialogs/NMODetail.vue'))
Vue.component('HTCDetail', () => import('./components/account/dialogs/HTCDetail.vue'))
Vue.component('UserDetail', () => import('./components/account/dialogs/UserDetail.vue'))
Vue.component('ProductDetail', () => import('./components/account/dialogs/ProductDetail.vue'))
Vue.component('LotNumberDetail', () => import('./components/account/dialogs/LotNumberDetail.vue'))
Vue.component('PatientDetail', () => import('./components/account/dialogs/PatientDetail.vue'))
Vue.component('ImportPatient', () => import('./components/account/dialogs/ImportPatient.vue'))
Vue.component('UtilizationDetail', () => import('./components/account/dialogs/UtilizationDetail.vue'))
Vue.component('ImportUtilization', () => import('./components/account/dialogs/ImportUtilization.vue'))
Vue.component('DownloadTemplate', () => import('./components/account/dialogs/DownloadTemplate.vue'));

import 'dropzone/dist/dropzone.css';
import jwtDecode from 'jwt-decode';