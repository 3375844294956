var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("account.controlPanel.tabs.translationMapping")))]),_c('v-card-subtitle',{domProps:{"innerHTML":_vm._s(_vm.$t('account.translationMapping.information'))}}),_c('v-card-text',[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("account.translationMapping.acuteBleedType")))]),_c('v-card-text',_vm._l((_vm.translationMappings.acuteBleedTypes),function(translation){return _c('v-row',{key:translation.id + translation.name},[_c('v-col',{staticClass:"text-subtitle-1",attrs:{"cols":"2"}},[_vm._v(_vm._s(translation.name))]),_c('v-col',{staticClass:"pb-2"},[_c('v-combobox',{ref:"cbo1",refInFor:true,attrs:{"append-icon":"","outlined":"","dense":"","multiple":"","small-chips":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
return [_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"label":"","small":""}},'v-chip',attrs,false),[(item === Object(item))?_c('span',[_vm._v(_vm._s(item.text))]):_c('span',[_vm._v(_vm._s(item))]),(item != translation.name)?_c('v-icon',{staticClass:"pl-2",attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("$delete")]):_vm._e()],1)]}}],null,true),model:{value:(translation.mappings),callback:function ($$v) {_vm.$set(translation, "mappings", $$v)},expression:"translation.mappings"}})],1)],1)}),1),_c('v-card-actions',{staticClass:"px-4 pb-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.saveTranslation}},[_vm._v(_vm._s(_vm.$t("actions.save")))])],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("account.translationMapping.diagnosis")))]),_c('v-card-text',_vm._l((_vm.translationMappings.diagnosisTypes),function(translation){return _c('v-row',{key:translation.id + translation.name},[_c('v-col',{staticClass:"text-subtitle-1",attrs:{"cols":"2"}},[_vm._v(_vm._s(translation.name))]),_c('v-col',{staticClass:"pb-2"},[_c('v-combobox',{ref:"cbo2",refInFor:true,attrs:{"append-icon":"","outlined":"","dense":"","multiple":"","small-chips":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
return [_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"label":"","small":""}},'v-chip',attrs,false),[(item === Object(item))?_c('span',[_vm._v(_vm._s(item.text))]):_c('span',[_vm._v(_vm._s(item))]),(item != translation.name)?_c('v-icon',{staticClass:"pl-2",attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("$delete")]):_vm._e()],1)]}}],null,true),model:{value:(translation.mappings),callback:function ($$v) {_vm.$set(translation, "mappings", $$v)},expression:"translation.mappings"}})],1)],1)}),1),_c('v-card-actions',{staticClass:"px-4 pb-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.saveTranslation}},[_vm._v(_vm._s(_vm.$t("actions.save")))])],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("account.translationMapping.treatmentType")))]),_c('v-card-text',_vm._l((_vm.translationMappings.treatmentTypes),function(translation){return _c('v-row',{key:translation.id + translation.name},[_c('v-col',{staticClass:"text-subtitle-1",attrs:{"cols":"2"}},[_vm._v(_vm._s(translation.name))]),_c('v-col',{staticClass:"pb-2"},[_c('v-combobox',{ref:"cbo3",refInFor:true,attrs:{"append-icon":"","outlined":"","dense":"","multiple":"","small-chips":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
return [_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"label":"","small":""}},'v-chip',attrs,false),[(item === Object(item))?_c('span',[_vm._v(_vm._s(item.text))]):_c('span',[_vm._v(_vm._s(item))]),(item != translation.name)?_c('v-icon',{staticClass:"pl-2",attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("$delete")]):_vm._e()],1)]}}],null,true),model:{value:(translation.mappings),callback:function ($$v) {_vm.$set(translation, "mappings", $$v)},expression:"translation.mappings"}})],1)],1)}),1),_c('v-card-actions',{staticClass:"px-4 pb-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.saveTranslation}},[_vm._v(_vm._s(_vm.$t("actions.save")))])],1)],1)],1),_c('v-snackbar',{staticStyle:{"top":"8px"},attrs:{"top":"","color":_vm.snackbarColor,"timeout":3000},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("CLOSE")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }