<template>
  <v-card elevation="1" class="my-2" tile>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <h3>{{ $t("account.integrations.mpro") }}</h3>
        </v-col>

        <!-- <v-col>
                {{
                    mproLastSyncDate == null
                        ? $t("account.integrations.neverSync")
                        : $t("account.integrations.lastSync", {
                                syncDate: $moment(mproLastSyncDate),
                            })
                }}
                </v-col>-->
        <v-col>
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" @click="updatePatientRecord">
                <template v-if="!mproLoading">
                  Update MPro Patient records
                  <!-- {{ $t("account.integrations.syncLots") }} -->
                </template>

                <template v-if="mproLoading">
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </template>
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" @click="updateProductRecords">
                <template v-if="!mproLoading"
                  >Update MPro Product records</template
                >

                <template v-if="mproLoading">
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </template>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" class="" @click="syncMproUtilizations()">
                <template v-if="!mproLoading">
                  {{
                    $t(
                      "account.integration.mproIntegration.importLatestUtilizations"
                    )
                  }}
                  <!-- {{ $t("account.integrations.syncLots") }} -->
                </template>

                <template v-if="mproLoading">
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </template>
              </v-btn>
              <h3 class="pt-3">
                {{
                  $t(
                    "account.integration.mproIntegration.utilizationsImportLog"
                  )
                }}
              </h3>
              <v-divider></v-divider>
              <v-data-table
                dense
                :loading="mproUtilizationLogLoading"
                :items="mproUtilizationLogs"
                :headers="mproUtilizationLogsHeaders"
                :sort-by="['fromDate']"
                :sort-desc="true"
                item-key="updateDate"
              >
                <template v-slot:item.fromDate="{ item }">{{
                  item.fromDate | moment("YYYY-MM-DD")
                }}</template>
                <template v-slot:item.toDate="{ item }">{{
                  item.toDate | moment("YYYY-MM-DD")
                }}</template>
                <template v-slot:item.isSuccess="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        x-small
                        :color="item.nbErrors == 0 ? 'success' : 'error'"
                        v-on="on"
                        v-bind="attrs"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                    </template>
                    <span v-if="item.nbErrors > 0">{{
                      $t("account.integration.mproIntegration.hasError")
                    }}</span>
                    <span v-else>{{
                      $t("account.integration.mproIntegration.isOk")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.wasRerun"
                        small
                        class="pl-2"
                        v-on="on"
                        v-bind="attrs"
                        >mdi-recycle-variant</v-icon
                      >
                    </template>
                    {{
                      $t("account.integration.mproIntegration.wasRerun", {
                        rerunDate: $moment(item.updateDate).format(
                          "YYYY-MM-DD"
                        ),
                      })
                    }}
                  </v-tooltip>
                </template>
                <template v-slot:item.downloadLogsAction="{ item }">
                  <v-btn
                    depressed
                    icon
                    @click="downloadLogForUtilization(item)"
                    :loading="mproUtilizationLogDownloading"
                    :disabled="mproLoading"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.rerunImportAction="{ item }">
                  <v-btn
                    v-if="item.nbErrors > 0"
                    depressed
                    icon
                    @click="syncMproUtilizations(item)"
                    :disabled="mproLoading"
                  >
                    <v-icon>mdi-recycle-variant</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar
      v-model="showSnackbar"
      top
      :color="showSnackbarColor"
      style="top: 8px"
      :timeout="3000"
    >
      {{ $t("account.actions.syncronized") }}
      <v-btn color="white" text @click="showSnackbar = false">CLOSE</v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="showConflictSnackbar"
      top
      color="red darken-2"
      style="top: 8px"
      :timeout="3000"
    >
      {{ $t("account.actions.syncBeingDone") }}
      <v-btn color="white" text @click="showConflictSnackbar = false"
        >CLOSE</v-btn
      >
    </v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      mproLastSyncDate: null,
      mproLoading: false,
      mproUtilizationLogLoading: false,
      mproUtilizationLogDownloading: false,
      mproUtilizationLogs: [],
      mproUtilizationLogsHeaders: [
        {
          text: this.$t("account.integrations.mproLogsHeaders.isSuccess"),
          value: "isSuccess",
        },
        {
          text: this.$t("account.integrations.mproLogsHeaders.fromDate"),
          value: "fromDate",
        },
        {
          text: this.$t("account.integrations.mproLogsHeaders.toDate"),
          value: "toDate",
        },
        {
          text: this.$t("account.integrations.mproLogsHeaders.nbErrors"),
          value: "nbErrors",
        },
        {
          text: this.$t(
            "account.integrations.mproLogsHeaders.downloadLogsAction"
          ),
          value: "downloadLogsAction",
        },
        {
          text: this.$t(
            "account.integrations.mproLogsHeaders.rerunImportAction"
          ),
          value: "rerunImportAction",
        },
      ],
      showConflictSnackbar: false,
      showSnackbar: false,
      showSnackbarColor: "success",
    };
  },
  computed: {},
  watch: {},
  methods: {
    download(blob, filename, applicationType) {
      blob = new Blob([blob], { type: applicationType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    downloadLogForUtilization(utilizationImport) {
      this.mproUtilizationLogDownloading = true;
      axios
        .get(
          `${this.$store.getters.getEnvironment}integration/mpro/downloadUtilizationImportLog/${utilizationImport.id}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const date = new Date(utilizationImport.creationDate);
          this.download(
            res.data,
            `MPro_utilizations_import_${date.getFullYear()}${
              date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()
            }${
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            }_${date.getHours()}${date.getMinutes()}${date.getSeconds()}.xlsx`,
            res.headers["content-type"]
          );
        })
        .catch((e) => {
          if (e.response.status == 404) {
            alert("Error while dowloading report");
          }
        })
        .finally(() => {
          this.mproUtilizationLogDownloading = false;
        });
    },
    loadImportUtilizationLogs() {
      this.mproUtilizationLogLoading = true;
      axios
        .get(
          `${this.$store.getters.getEnvironment}integration/mpro/getImportUtilizationLogs`
        )
        .then((res) => {
          this.mproUtilizationLogs = res.data;
        })
        .finally(() => {
          this.mproUtilizationLogLoading = false;
        });
    },

    syncMproUtilizations(utilisationImport) {
      this.mproLoading = true;
      var date = new Date(
        utilisationImport == null ? Date.now() : utilisationImport.creationDate
      );
      var url = `${this.$store.getters.getEnvironment}integration/mpro/import/utilizations`;
      if (utilisationImport != null) url += "/" + utilisationImport.id;
      axios
        .get(url, { responseType: "blob" })
        .then((res) => {
          this.showSnackbar = true;
          this.download(
            res.data,
            `MPro_utilizations_import_${date.getFullYear()}${
              date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()
            }${
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            }_${date.getHours()}${date.getMinutes()}${date.getSeconds()}.xlsx`,
            res.headers["content-type"]
          );
          //console.table(res.data);
        })
        .finally(() => {
          this.mproLoading = false;
          setTimeout(() => {
            this.loadImportUtilizationLogs();
          }, 100);
        });
    },
    updatePatientRecord() {
      this.mproLoading = true;
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}integration/mpro/sync/patients`
      );

      return xhr
        .then((x) => {
          this.showSnackbar = true;
        })
        .catch((e) => {
          if (e.response.status == 409 || e.response.status == 403) {
            this.showConflictSnackbar = true;
          }
        })
        .finally((_) => {
          this.mproLoading = false;
        });
    },
    updateProductRecords() {
      this.mproLoading = true;
      axios
        .get(
          `${this.$store.getters.getEnvironment}integration/mpro/sync/products`
        )
        .then((x) => {
          this.showSnackbar = true;
        })
        .catch((e) => {
          if (e.response.status == 409 || e.response.status == 403) {
            this.showConflictSnackbar = true;
          }
        })
        .finally((_) => {
          this.mproLoading = false;
        });
    },
  },
  created() {
    this.loadImportUtilizationLogs();
  },
};
</script>
