 <template>
  <div>
    <v-card tile elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="5" class="d-flex">
            <v-text-field
              v-model="search"
              :label="$t('actions.filters')"
              append-icon="mdi-magnify"
              hide-details=""
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="d-flex">
            <v-btn color="blue" text small class="white--text" @click="load()"
              ><v-icon>mdi-refresh</v-icon>{{ $t("actions.refresh") }}</v-btn
            >
          </v-col>
          <v-col cols="6" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              v-if="this.$store.getters.getIsAdmin"
              text
              tile
              small
              class="green--text"
              @click="openDetails(-1)"
              ><v-icon>mdi-plus</v-icon>{{ $t("actions.add") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :key="this.$store.state.productKey"
          :items="productList"
          :sort-by="['name']"
          :loading="isDataLoading"
          :loading-text="$t('app.loading')"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
          :items-per-page="this.$store.state.itemsPerPage"
          :search="searchValue"
          dense
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="openDetails(item.id)" icon>
              <v-icon class="pa-3"> mdi-pencil </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template> 

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      searchValue: "",
      search: "",
      productList: [],
      isDataLoading: false,
      timeout: undefined,
      headers: [
        {
          text: this.$t("product.headerRow.action"),
          value: "action",
          class: "grey white--text",
          sortable: false,
        },
        {
          text: this.$t("product.headerRow.name"),
          value: "name",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.potency"),
          value: "potency",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.manufacturer"),
          value: "manufacturer",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.factorType"),
          value: "factorTypeName",
          class: "grey white--text",
        },
      ],
    };
  },
  computed: {
    refreshFlag() {
      return this.$store.state.productsKey;
    },
  },
  watch: {
    refreshFlag() {
      this.load();
    },
    search: function (newVal, oldVal) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchValue = newVal;
      }, 300);
    },
  },
  methods: {
    load() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(this.$store.getters.getEnvironment + "product/")
        .then((response) => {
          this.isDataLoading = false;
          this.productList = response.data;
        })
        .catch((e) => {});
    },
    openDetails(value) {
      this.$store.commit("showModal", {
        componentName: "ProductDetail",
        parameter: { id: value },
      });
    },
  },
  created() {
    this.load();
  },
};
</script>

