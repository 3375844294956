 <template>
  <div>
    <v-card tile elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="5" class="d-flex">
            <v-text-field
              v-model="search"
              :label="$t('actions.filters')"
              append-icon="mdi-magnify"
              hide-details=""
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="d-flex">
            <v-btn color="blue" text small class="white--text" @click="load()"
              ><v-icon>mdi-refresh</v-icon>{{ $t("actions.refresh") }}</v-btn
            >
          </v-col>

          <v-col cols="6" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              v-if="this.$store.getters.getIsAdmin"
              color="green"
              text
              small
              class="white--text"
              @click="openDetail(-1)"
              ><v-icon>mdi-plus</v-icon>{{ $t("actions.add") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :key="this.$store.state.nmoKey"
          :items="datas"
          :sort-by="['name']"
          :loading="isDataLoading"
          :loading-text="$t('app.loading')"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
          :items-per-page="this.$store.state.itemsPerPage"
          :search="search"
          dense
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="openDetail(item.id)" icon>
              <v-icon class="pa-3">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.countryCode="{ item }">
            <span>{{ $t("country." + item.countryCode) }}</span>
          </template>
          <template v-slot:item.share="{ item }">
            <v-checkbox
              v-model="item.share"
              readonly
              disabled
              hide-details=""
            ></v-checkbox>
          </template>
          <template v-slot:item.isMultiHTC="{ item }">
            <v-checkbox
              v-model="item.isMultiHTC"
              readonly
              disabled
              hide-details=""
            ></v-checkbox>
          </template>
          <template v-slot:item.lastReportDate="{ item }">
            <span v-if="item.lastReportDate != null">
              {{ item.lastReportDate | moment("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template> 

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      isDataLoading: false,
      datas: [],
      search: "",
      headers: [
        {
          text: this.$t("nmo.headerRow.action"),
          value: "action",
          class: "grey white--text",
          sortable: false,
        },
        {
          text: this.$t("nmo.headerRow.name"),
          value: "name",
          class: "grey white--text",
        },
        {
          text: this.$t("nmo.headerRow.country"),
          value: "countryCode",
          class: "grey white--text",
        },
        {
          text: this.$t("nmo.headerRow.type"),
          value: "nmoTypeName",
          class: "grey white--text",
        },
        {
          text: this.$t("nmo.headerRow.share"),
          value: "share",
          class: "grey white--text",
        },
        {
          text: this.$t("nmo.headerRow.isMultiHTC"),
          value: "isMultiHTC",
          class: "grey white--text",
        },
        {
          text: this.$t("nmo.headerRow.lastReportDate"),
          value: "lastReportDate",
          class: "grey white--text",
        },
        {
          text: this.$t("nmo.headerRow.numberOfAffiliatedHTC"),
          value: "htcCount",
          class: "grey white--text",
        },
      ],
    };
  },
  computed: {
    refreshFlag() {
      return this.$store.state.nmoKey;
    },
  },
  watch: {
    refreshFlag() {
      this.load();
    },
  },
  methods: {
    load() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(this.$store.getters.getEnvironment + "nmo/")
        .then((response) => {
          this.datas = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    openDetail(value) {
      this.$store.commit("showModal", {
        componentName: "NMODetail",
        parameter: { id: value },
        width: 1000,
        maxwidth: 1000
      });
    },
    convertDate(date) {
      return this.$moment.parseZone(date).utc().format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.load();
  },
};
</script>

