var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('actions.filters'),"append-icon":"mdi-magnify","hide-details":"","dense":""},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.activeStatuses,"label":_vm.$t('patient.headerRow.status'),"item-text":"text","item-value":"value","hide-details":"","dense":""},on:{"change":function($event){return _vm.load()}},model:{value:(_vm.activeFilters.status),callback:function ($$v) {_vm.$set(_vm.activeFilters, "status", $$v)},expression:"activeFilters.status"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue","text":"","small":""},on:{"click":function($event){return _vm.load()}}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(_vm._s(_vm.$t("actions.refresh")))],1)],1),_c('v-col',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"green--text",attrs:{"small":"","text":"","tile":""},on:{"click":function($event){return _vm.openDetail(-1)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("actions.add")))],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"green--text",attrs:{"small":"","text":"","title":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c('v-list',_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{on:{"click":item.action}},[_c('v-btn',{staticClass:"green--text menu-button",attrs:{"tile":"","text":""}},[(item.icon != null)?_c('v-icon',[_vm._v(_vm._s(item.icon))]):_vm._e(),_vm._v(" "+_vm._s(item.title))],1)],1)],1)}),1)],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{key:this.$store.state.patientKey,attrs:{"headers":_vm.headers,"items":_vm.datas,"sort-by":['name'],"loading":_vm.isDataLoading,"loading-text":_vm.$t('app.loading'),"footer-props":{
          'items-per-page-options': [10, 20, 30, 40, 50],
        },"items-per-page":this.$store.state.itemsPerPage,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDetail(item.id)}}},[_c('v-icon',{staticClass:"pa-3"},[_vm._v(" mdi-pencil ")])],1)]}},{key:"item.nmoCountryCode",fn:function(ref){
        var item = ref.item;
return [(item.nmoCountryCode != null)?_c('span',[_vm._v(_vm._s(_vm.$t("country." + item.nmoCountryCode)))]):_vm._e()]}},{key:"item.diagnosisName",fn:function(ref){
        var item = ref.item;
return [(item.diagnosisName != null)?_c('span',[_vm._v(_vm._s(_vm.$t("patient.diagnosis." + item.diagnosisName)))]):_vm._e()]}},{key:"item.share",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":"","disabled":""},model:{value:(item.share),callback:function ($$v) {_vm.$set(item, "share", $$v)},expression:"item.share"}})]}},{key:"item.dateOfBirth",fn:function(ref){
        var item = ref.item;
return [(item.dateOfBirth != null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.dateOfBirth.substring(0, 10),"YYYY-MM-DD"))+" ")]):_vm._e()]}},{key:"item.creationDate",fn:function(ref){
        var item = ref.item;
return [(item.creationDate != null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.creationDate,"YYYY-MM-DD HH:mm:ss"))+" ")]):_vm._e()]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [(item.updatedAt != null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,"YYYY-MM-DD HH:mm:ss"))+" ")]):_vm._e()]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.isActive ? _vm.$t("app.yes") : _vm.$t("app.no"))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }