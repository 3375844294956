<template v-slot:activator="{ on }">
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn
        text
        x-small
        @click="close()"
        v-on="on"
        key="account"
        style="margin-left: -17px; margin-top: -10px"
        >{{ $t("signin.forgotPassword") }}</v-btn
      >
    </template>
    <v-card elevation="0">
      <v-card-title primary-title>
        <span>{{ $t("signin.forgotPassword") }}</span>
      </v-card-title>
      <v-stepper v-model="position" class="elevation-0">
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="position > 1" step="1" :color="this.$store.state.loadingBarColor" >{{
            $t("forgotpassword.identification")
          }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="position > 2" step="2" :color="this.$store.state.loadingBarColor">{{
            $t("forgotpassword.confirmation")
          }}</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="form" v-model="valid" @keyup.native.enter="send()">
              <br>
              <v-text-field
                :label="$t('signin.email')"
                v-model="email"
                required
                outlined
                :rules="[emailFormatRules]"
              ></v-text-field>
            </v-form>
            <v-layout justify-end>
              <v-btn text @click="dialog = false">{{
                $t("forgotpassword.cancel")
              }}</v-btn>
              <v-btn text @click="send()" :disabled="!valid" type="submit">{{
                $t("forgotpassword.next")
              }}</v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="mb-5" elevation="0">{{
              sendEmailStatusMessage
            }}</v-card>

            <div class="text-xs-center">
              <v-progress-circular
                :color="this.$store.state.loadingBarColor"
                :size="70"
                :width="7"
                indeterminate
                v-show="isProcessing"
              ></v-progress-circular>
            </div>

            <v-layout justify-end>
              <v-btn text @click="close()" :disabled="isProcessing">{{
                $t("actions.close")
              }}</v-btn>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      dialog: false,
      valid: false,
      isSending: false,
      isProcessing: false,
      sendEmailStatusMessage: "",
      position: 0,
      emailFormatRules: (v) => {
        if (v.length > 0) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!pattern.test(v)) {
            return this.$t("user.creationForm.errors.emailInvalid");
          }
        } else {
          return this.$t("forgotpassword.emailRequired");
        }
        return true;
      },
    };
  },
  methods: {
    send() {
      if (this.$refs.form.validate()) {
        this.position = 2;
        this.isProcessing = true;
        this.sendEmailStatusMessage = this.$t("forgotpassword.emailSending");
        axios
          .post(this.$store.getters.getEnvironment + "auth/forgotpassword/", {
            Email: this.email,
          })
          .then(() => {
            //this.dialog = false;
            this.isProcessing = false;
            this.email = "";
            this.isSending = false;
            this.sendEmailStatusMessage = this.$t("forgotpassword.emailok");
          })
          .catch(() => {
            this.isProcessing = false;
            this.isSending = false;
            this.sendEmailStatusMessage = this.$t(
              "forgotpassword.forgotpasswordErrorMEssage"
            );
          });
      } else {
        this.position = 1;
      }
    },
    close() {
      this.dialog = false;
      this.email = "";
      this.position = 1;
      this.sendEmailStatusMessage = "";
    },
  },
};
</script>

