<template>
  <v-card elevation="0" class="my-4 mx-4" v-if="isAdmin">
    <v-card-title>{{ $t("account.lotStatus.title") }}</v-card-title>
    <v-card-text>
      <v-card elevation="1" class="my-2" tile>
        <v-card-text>
          <v-form v-model="form"
            ><v-row>
              <v-col md="4" v-for="x in [1, 2, 3]" :key="x">
                <v-row>
                  <v-col md="8">{{
                    $t("account.lotStatus.headers.status")
                  }}</v-col>
                  <v-col md="4">{{
                    $t("account.lotStatus.headers.usable")
                  }}</v-col>
                </v-row>
              </v-col>
              <v-col
                md="4"
                class="py-0"
                v-for="status in lotStatuses"
                :key="status.status"
              >
                <v-row align="center">
                  <v-col md="8" class="py-0"
                    ><p class="center">{{ status.status }}</p></v-col
                  >
                  <v-col md="3" class="py-0"
                    ><v-checkbox v-model="status.usable"></v-checkbox
                  ></v-col>
                </v-row> </v-col
            ></v-row>
          </v-form> </v-card-text
      ></v-card>
      <v-snackbar
        v-model="showSucessSnackbar"
        top
        color="success"
        style="top: 8px"
        :timeout="3000"
      >
        {{ $t("account.actions.lotStatus.saved") }}
        <v-btn color="white" text @click="showSuccessSnackbar = false">
          CLOSE
        </v-btn>
      </v-snackbar>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="saveStatuses">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      form: null,
      showSucessSnackbar: false,
      loading: false,
      statuses: [],
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },
    lotStatuses() {
      var cp = this.statuses;
      cp.sort((a, b) =>
        a.status > b.status
          ? 1
          : a.status === b.status
          ? a.status > b.status
            ? 1
            : -1
          : -1
      );
      return cp;
    },
  },
  watch: {},
  methods: {
    loadStatuses() {
      this.loading = true;
      axios
        .get(`${this.$store.getters.getEnvironment}lotstatus`)
        .then((res) => {
          this.statuses = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveStatuses() {
      this.loading = true;
      var statuses = this.statuses.map((x) => ({ key: x.id, value: x.usable }));
      axios
        .patch(`${this.$store.getters.getEnvironment}lotstatus`, {
          prop: "Aplac",
          statuses: statuses,
        })
        .then(() => {
          this.showSucessSnackbar = true;
        })
        .catch((res) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.loadStatuses();
  },
};
</script>