 <template>
  <div>
    <v-card tile elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="5" class="d-flex">
            <v-text-field
              v-model="search"
              :label="$t('actions.filters')"
              append-icon="mdi-magnify"
              hide-details=""
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="d-flex">
            <v-btn color="blue" text small class="white--text" @click="load()">
              <v-icon>mdi-refresh</v-icon>
              {{ $t("actions.refresh") }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex">
            <v-spacer></v-spacer>
            <v-checkbox
              dense
              hide-details=""
              class="ma-0 mr-1"
              v-if="showUsableOnlyCheckbox"
              :label="$t('product.filters.showUsable')"
              v-model="filters.showUsableOnly"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :key="this.$store.state.productKey"
          :items="filteredData"
          :sort-by="['name']"
          :loading="isDataLoading"
          :loading-text="$t('app.loading')"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
          :items-per-page="this.$store.state.itemsPerPage"
          :search="search"
          dense
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="openDetail(item.productId)" icon>
              <v-icon class="pa-3">mdi-eye</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.countriesName="{ item }">
            <span v-html="item.countriesName"></span>
          </template>
          <template v-slot:item.expiryDate="{ item }">{{
            item.expiryDate | moment("YYYY-MM-DD")
          }}</template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template> 

<script>
import axios from "axios";
export default {
  data() {
    return {
      isDataLoading: false,
      datas: [],
      search: "",
      filters: {
        showUsableOnly: true,
      },
    };
  },
  computed: {
    showUsableOnlyCheckbox() {
      return this.$store.getters.getIsAdmin;
    },
    refreshFlag() {
      return this.$store.state.lotNumbers;
    },
    filteredData() {
      if (this.filters.showUsableOnly)
        return this.datas.filter((x) => x.isUsable);
      else return this.datas;
    },
    headers() {
      var h = [
        {
          text: this.$t("product.headerRow.action"),
          value: "action",
          class: "grey white--text",
          sortable: false,
        },
        {
          text: this.$t("product.headerRow.name"),
          value: "name",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.potency"),
          value: "potency",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.manufacturer"),
          value: "manufacturer",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.factorType"),
          value: "factorType",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.lotNumber"),
          value: "lotNumber",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.country"),
          value: "countriesName",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.expiryDate"),
          value: "expiryDate",
          class: "grey white--text",
        },
      ];

      if (!this.filters.showUsableOnly) {
        h = h.concat([
          {
            text: this.$t("product.headerRow.isUsable"),
            value: "isUsable",
            class: "grey white--text",
          },
          {
            text: this.$t("product.headerRow.status"),
            value: "status",
            class: "grey white--text",
          },
        ]);
      }
      return h;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.load();
      },
    },
    refreshFlag() {
      this.load();
    },
  },
  methods: {
    load() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      const params = {
        showUsableOnly: this.filters.showUsableOnly ? true : "",
      };
      axios
        .get(this.$store.getters.getEnvironment + "productwithlots", { params })
        .then((response) => {
          this.datas = response.data;
          this.isDataLoading = false;

          for (let i = 0; i < this.datas.length; i++) {
            for (let j = 0; j < this.datas[i].countries.length; j++) {
              this.datas[i].countriesName =
                this.datas[i].countriesName +
                this.$t("country." + this.datas[i].countries[j]) +
                "</br>";
            }
          }
        })
        .catch((e) => {});
    },
    openDetail(value) {
      this.$store.commit("showModal", {
        componentName: "ProductDetail",
        parameter: { id: value },
      });
    },
  },
  created() {
    this.load();
  },
};
</script>

