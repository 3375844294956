<template>
  <v-form ref="form" @keyup.native.enter="reset()" v-model="valid">
    <v-card class="mx-auto" max-width="500" elevation="0">
      <v-card-text>
        <v-text-field
          :label="$t('changePassword.newPassword')"
          v-model="password"
          type="password"
          required
          outlined
          :rules="passwordRules"
        ></v-text-field>
        <v-text-field
          :label="$t('changePassword.confirmPassword')"
          v-model="passwordConfirmation"
          type="password"
          required
          outlined
          :rules="passwordConfirmationRules"
        ></v-text-field>
        <v-card-text> </v-card-text>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="reset" text :disabled="isSending || !valid">{{
          $t("changePassword.confirmButton")
        }}</v-btn>
        <v-progress-circular
          v-if="isSending"
          indeterminate
          color="#0071c5"
        ></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  props: ["enc"],
  data() {
    return {
      valid: false,
      password: "",
      dialog: false,
      passwordConfirmation: "",
      isSending: false,
      passwordRules: [
        (v) =>
          (v && v.length >= 8) || this.$t("changePassword.passwordCharacters"),
        (v) => {
          if (v.length > 0) {
            const pattern = /^(?=.*[!-\/:-@\[-`{-~]).{8,}$/i; //eslint-disable-line
            return pattern.test(v) || this.$t("changePassword.passwordSpecial");
          } else {
            return this.$t("changePassword.requiredPassword");
          }
        },
      ],
      passwordConfirmationRules: [
        (v) => !!v || this.$t("changePassword.requiredPassword"),
        (v) =>
          (v && v.length >= 7) || this.$t("changePassword.passwordCharacters"),
        (v) =>
          (v && v === this.password) ||
          this.$t("changePassword.matchingPassword"),
        (v) => {
          if (v.length > 0) {
            const pattern = /^(?=.*[!-\/:-@\[-`{-~]).{8,}$/i; //eslint-disable-line
            return pattern.test(v) || this.$t("changePassword.passwordSpecial");
          } else {
            return this.$t("changePassword.requiredPassword");
          }
        },
      ],
    };
  },
  mounted: function () {
    this.dialog = true;
  },
  methods: {
    reset() {
      if (this.$refs.form.validate()) {
        this.isSending = true;
        axios
          .post(this.$store.getters.getEnvironment + "auth/resetpassword/", {
            Enc: this.enc,
            Password: this.password,
          })
          .then(() => {
            this.isSending = false;
            this.$store.commit("showMessageOK", this.$t("app.saved"));
            this.$router.push({ name: "Login" });
          })
          .catch(() => {
            this.isSending = false;
          });
      }
    },
    close() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
