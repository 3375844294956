<template>
  <v-card elevation="0">
    <v-card-title>{{
      $t("account.controlPanel.tabs.translationMapping")
    }}</v-card-title>
    <v-card-subtitle
      v-html="$t('account.translationMapping.information')"
    ></v-card-subtitle>
    <v-card-text>
      <v-card>
        <v-card-title>{{
          $t("account.translationMapping.acuteBleedType")
        }}</v-card-title>
        <v-card-text>
          <v-row
            v-for="translation in translationMappings.acuteBleedTypes"
            :key="translation.id + translation.name"
          >
            <v-col cols="2" class="text-subtitle-1">{{
              translation.name
            }}</v-col>
            <v-col class="pb-2">
              <v-combobox
                v-model="translation.mappings"
                append-icon
                outlined
                dense
                multiple
                small-chips
                hide-details
                ref="cbo1"
              >
                <template v-slot:selection="{ attrs, item, parent }">
                  <v-chip
                    v-bind="attrs"
                    :color="`${item.color} lighten-3`"
                    label
                    small
                  >
                    <span v-if="item === Object(item)">{{ item.text }}</span>
                    <span v-else>{{ item }}</span>
                    <v-icon
                      v-if="item != translation.name"
                      small
                      class="pl-2"
                      @click="parent.selectItem(item)"
                      >$delete</v-icon
                    >
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveTranslation">{{
            $t("actions.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="mt-4">
        <v-card-title>{{
          $t("account.translationMapping.diagnosis")
        }}</v-card-title>
        <v-card-text>
          <v-row
            v-for="translation in translationMappings.diagnosisTypes"
            :key="translation.id + translation.name"
          >
            <v-col cols="2" class="text-subtitle-1">{{
              translation.name
            }}</v-col>
            <v-col class="pb-2">
              <v-combobox
                v-model="translation.mappings"
                append-icon
                outlined
                dense
                multiple
                small-chips
                hide-details
                ref="cbo2"
              >
                <template v-slot:selection="{ attrs, item, parent }">
                  <v-chip
                    v-bind="attrs"
                    :color="`${item.color} lighten-3`"
                    label
                    small
                  >
                    <span v-if="item === Object(item)">{{ item.text }}</span>
                    <span v-else>{{ item }}</span>
                    <v-icon
                      v-if="item != translation.name"
                      small
                      class="pl-2"
                      @click="parent.selectItem(item)"
                      >$delete</v-icon
                    >
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveTranslation">{{
            $t("actions.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="mt-4">
        <v-card-title>{{
          $t("account.translationMapping.treatmentType")
        }}</v-card-title>
        <v-card-text>
          <v-row
            v-for="translation in translationMappings.treatmentTypes"
            :key="translation.id + translation.name"
          >
            <v-col cols="2" class="text-subtitle-1">{{
              translation.name
            }}</v-col>
            <v-col class="pb-2">
              <v-combobox
                v-model="translation.mappings"
                append-icon
                outlined
                dense
                multiple
                small-chips
                hide-details
                ref="cbo3"
              >
                <template v-slot:selection="{ attrs, item, parent }">
                  <v-chip
                    v-bind="attrs"
                    :color="`${item.color} lighten-3`"
                    label
                    small
                  >
                    <span v-if="item === Object(item)">{{ item.text }}</span>
                    <span v-else>{{ item }}</span>
                    <v-icon
                      v-if="item != translation.name"
                      small
                      class="pl-2"
                      @click="parent.selectItem(item)"
                      >$delete</v-icon
                    >
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveTranslation">{{
            $t("actions.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>

    <v-snackbar
      v-model="showSnackbar"
      top
      :color="snackbarColor"
      style="top: 8px"
      :timeout="3000"
    >
      {{ snackbarText }}
      <v-btn color="white" text @click="showSnackbar = false">CLOSE</v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      showSnackbar: false,
      snackbarColor: "success",
      snackbarText: "",
      translationMappings: {
        acuteBleedTypes: [],
        diagnosisTypes: [],
        treatmentTypes: [],
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    loadTranslationMappings() {
      axios
        .get(`${this.$store.getters.getEnvironment}translationMapping`)
        .then((res) => {
          this.translationMappings = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    saveTranslation() {
      setTimeout(() => {
        axios
          .post(
            `${this.$store.getters.getEnvironment}translationMapping`,
            this.translationMappings
          )
          .then((res) => {
            this.showSnackbar = true;
            this.snackbarColor = "success";
            this.snackbarText = this.$t("account.translationMapping.saved");
            this.loadTranslationMappings();
          })
          .catch((err) => {
            this.showSnackbar = true;
            this.snackbarColor = "error";
            this.snackbarText = this.$t("account.translationMapping.notSaved");
          });
      }, 100);
    },
  },
  created() {
    this.loadTranslationMappings();
  },
};
</script>
