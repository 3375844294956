import Vue from 'vue'
import Vuex from 'vuex'
import {
    Role
} from "@/helpers/role";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modals: [],
        modalSeconds: [],
        showSnackbar: {
            key: 0,
            text: "",
        },
        connectedUser: {
            name: "",
            email: "",
            role: "",
            nmoId: 0,
            htcId: 0,
            htcGroupId: 0,
            lang: "en",
            canDeleteUtilization: false
        },
        currentPage: "",
        languageKey: 0,
        nmoKey: 0,
        htcKey: 0,
        userKey: 0,
        patientKey: 0,
        productsKey: 0,
        lotNumbers: 0,
        lotKey: 0,
        utilizationKey: 0,
        itemsPerPage: 10,
        modalOptions: {
            size: "50%",
            secondSize: "60%"
        },
        loadingBarColor: "#DB2B35",
        environment: process.env.VUE_APP_BACKEND_URL,
        isQuickLoginAvailable: process.env.VUE_APP_DISPLAY_QUICK_LOGIN
    },
    mutations: {
        showMessageOK(state, text) {
            state.showSnackbar.key = state.showSnackbar.key + 1,
                state.showSnackbar.text = text,
                state.showSnackbar.color = "green"
        },
        showMessageError(state, text) {
            state.showSnackbar.key = state.showSnackbar.key + 1,
                state.showSnackbar.text = text,
                state.showSnackbar.color = "red"
        },
        showModal(state, {
            componentName,
            parameter,
            width,
            maxwidth
        }) {
            state.modals.push({
                component: componentName,
                parameter: parameter,
                width: width,
                maxwidth: maxwidth
            });
        },
        showModalSecond(state, {
            componentName,
            parameter,
            width,
            maxwidth
        }) {
            state.modalSeconds.push({
                component: componentName,
                parameter: parameter,
                width: width,
                maxwidth: maxwidth
            });
        },
        hideModal(state) {
            state.modals.splice(state.modals.length - 1);
        },
        hideModalSecond(state) {
            state.modalSeconds.splice(state.modalSeconds.length - 1);
        },
        setConnectedUser(state, {
            id,
            name,
            guid,
            email,
            role,
            nmoId,
            htcGroupId,
            htcId,
            lang,
            canDeleteUtilization
        }) {
            state.connectedUser.id = id;
            state.connectedUser.name = name;
            state.connectedUser.guid = guid;
            state.connectedUser.email = email;
            state.connectedUser.role = role;
            state.connectedUser.nmoId = nmoId;
            state.connectedUser.htcGroupId = htcGroupId;
            state.connectedUser.htcId = htcId;
            state.connectedUser.lang = lang
            state.connectedUser.canDeleteUtilization = canDeleteUtilization
        },
        setCurrentPage(state, currentPage) {
            state.currentPage = currentPage;
        },
        setLanguageKey(state, value) {
            state.languageKey = state.languageKey + 1;
            state.productKey = state.productKey + 1;
        },
        setNMOKey(state, value) {
            state.nmoKey = state.nmoKey + 1;
        },
        setHTCKey(state, value) {
            state.htcKey = state.htcKey + 1;
        },
        setUserKey(state, value) {
            state.userKey = state.userKey + 1;
        },
        setPatientKey(state, value) {
            state.patientKey = state.patientKey + 1;
        },
        setProductsKey(state, value) {
            state.productsKey = state.productsKey + 1;
        },
        setLotKey(state, value) {
            state.lotKey = state.lotKey + 1;
        },
        setUtilizationKey(state, value) {
            state.utilizationKey = state.utilizationKey++;
        },
        setShowSnackbar(state, {
            isVisible
        }) {
            state.showSnackbar.isVisible = isVisible;
        },
        setConnectdUserLang(state, lang) {
            state.connectedUser.lang = lang;
        }
    },
    actions: {},
    modules: {},
    getters: {
        getCanDeleteUtilization: (state) => {
            return state.connectedUser.canDeleteUtilization;
        },
        getEnvironment: (state) => {
            return state.environment;
        },
        getIsQuickLoginAvailable: (state) => {
            if (state.isQuickLoginAvailable != undefined && state.isQuickLoginAvailable.toLowerCase().trim() == 'true')
                return true;
            return false;
        },
        getHTCId: (state) => {
            return state.connectedUser.htcId;
        },
        getIsAdmin: (state) => {
            return state.connectedUser.role == Role.Admin;
        },
        getIsNMOAdmin: (state) => {
            return state.connectedUser.role == Role.NMOAdmin;
        },
        getLang: (state) => {
            return state.connectedUser.lang
        },
        getLangId: (state) => {
            switch (state.connectedUser.lang) {
                case 'en':
                    return 1;
                case 'fr':
                    return 2;
                case 'es':
                    return 3;
                case 'ru':
                    return 4;
                default:
                    return -1;
            }

        },
        getNMOId: (state) => {
            return state.connectedUser.nmoId;
        },
    }
})