<template>
  <v-card elevation="0" class="my-4 mx-4" v-if="isAdmin">
    <v-card-title>
      {{ $t("account.controlPanel.tabs.integration") }}
    </v-card-title>
    <v-card-text>
      <v-card elevation="1" class="my-2" tile>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <h3>{{ $t("account.integrations.leadCommerce") }}</h3>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                @click="syncLeadCommerce()"
                :disabled="leadCommerceLoading"
              >
                <template v-if="!leadCommerceLoading">{{
                  $t("account.integrations.syncLots")
                }}</template>
                <template v-if="leadCommerceLoading">
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </template>
              </v-btn>
            </v-col>
            <v-col>
              {{
                lcLastSyncDate == null
                  ? $t("account.integrations.neverSync")
                  : $t("account.integrations.lastSync", {
                      syncDate: $moment(lcLastSyncDate),
                    })
              }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card elevation="1" class="my-2" tile>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <h3>{{ $t("account.integrations.odoo") }}</h3>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                @click="syncOdoo()"
                :disabled="odooLoading"
              >
                <template v-if="!odooLoading">{{
                  $t("account.integrations.syncLots")
                }}</template>
                <template v-if="odooLoading">
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </template>
              </v-btn>
            </v-col>
            <v-col>
              {{
                odooLastSyncDate == null
                  ? $t("account.integrations.neverSync")
                  : $t("account.integrations.lastSync", {
                      syncDate: $moment(odooLastSyncDate),
                    })
              }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <mpro-integration></mpro-integration>
    </v-card-text>
    <v-snackbar
      v-model="showSnackbar"
      top
      :color="showSnackbarColor"
      style="top: 8px"
      :timeout="3000"
    >
      {{ $t("account.actions.syncronized") }}
      <v-btn color="white" text @click="showSnackbar = false">CLOSE</v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="showConflictSnackbar"
      top
      color="red darken-2"
      style="top: 8px"
      :timeout="3000"
    >
      {{ $t("account.actions.syncBeingDone") }}
      <v-btn color="white" text @click="showConflictSnackbar = false"
        >CLOSE</v-btn
      >
    </v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios";
import MproIntegration from "./MProIntegration.vue";
export default {
  components: { MproIntegration },
  data() {
    return {
      lcLastSyncDate: null,
      odooLastSyncDate: null,
      leadCommerceLoading: false,
      odooLoading: false,
      mproLastSyncDate: null,
      mproLoading: false,
      mproUtilizationLogLoading: false,
      mproUtilizationLogs: [],
      mproUtilizationLogsHeaders: [
        {
          text: this.$t("account.integrations.mproLogsHeaders.isSuccess"),
          value: "isSuccess",
        },
        {
          text: this.$t("account.integrations.mproLogsHeaders.fromDate"),
          value: "fromDate",
        },
        {
          text: this.$t("account.integrations.mproLogsHeaders.toDate"),
          value: "toDate",
        },
        {
          text: this.$t("account.integrations.mproLogsHeaders.nbErrors"),
          value: "nbErrors",
        },
        {
          text: this.$t(
            "account.integrations.mproLogsHeaders.downloadLogsAction"
          ),
          value: "downloadLogsAction",
        },
        {
          text: this.$t(
            "account.integrations.mproLogsHeaders.rerunImportAction"
          ),
          value: "rerunImportAction",
        },
      ],
      showConflictSnackbar: false,
      showSnackbar: false,
      showSnackbarColor: "success",
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },
  },
  watch: {},
  methods: {
    loadImportUtilizationLogs() {
      this.mproUtilizationLogLoading = true;
      axios
        .get(
          `${this.$store.getters.getEnvironment}integration/mpro/getImportUtilizationLogs`
        )
        .then((res) => {})
        .finally(() => {
          this.mproUtilizationLogLoading = false;
        });
    },
    syncLeadCommerce() {
      this.leadCommerceLoading = true;
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}integration/leadCommerce/sync/lots`,
        { responseType: "blob" }
      );

      var date = new Date(Date.now());
      return xhr
        .then((res) => {
          this.download(
            res.data,
            `LeadCommerce_SynchronizationResult_${date.getFullYear()}${
              date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()
            }${
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            }_${date.getHours()}${date.getMinutes()}${date.getSeconds()}.csv`,
            "application/csv"
          );
          this.showSnackbar = true;
          this.lcLastSyncDate = date;
        })
        .catch((e) => {
          if (e.response.status == 409) {
            this.showConflictSnackbar = true;
          }
        })
        .finally(() => {
          this.leadCommerceLoading = false;
        });
    },
    syncOdoo() {
      this.odooLoading = true;
      var xhr = axios.post(`${this.$store.getters.getEnvironment}odoo`);

      var date = new Date(Date.now());
      return xhr
        .then((res) => {
          this.showSnackbar = true;
          this.odooLastSyncDate = date;
        })
        .catch((e) => {
          if (e.response.status == 409) {
            this.showConflictSnackbar = true;
          }
        })
        .finally(() => {
          this.odooLoading = false;
        });
    },
    syncMpro() {
      this.mproLoading = true;
      var date = new Date(Date.now());
      axios
        .get(
          `${this.$store.getters.getEnvironment}integration/mpro/import/utilizations`,
          { responseType: "blob" }
        )
        .then((res) => {
          this.showSnackbar = true;
          this.download(
            res.data,
            `MPro_utilizations_import_${date.getFullYear()}${
              date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()
            }${
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            }_${date.getHours()}${date.getMinutes()}${date.getSeconds()}.xlsx`,
            res.headers["content-type"]
          );
          //console.table(res.data);
        })
        .finally((_) => {
          this.mproLoading = false;
        });
    },

    updatePatientRecord() {
      this.mproLoading = true;
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}integration/mpro/sync/patients`
      );

      return xhr
        .then((x) => {
          this.showSnackbar = true;
        })
        .catch((e) => {
          if (e.response.status == 409 || e.response.status == 403) {
            this.showConflictSnackbar = true;
          }
        })
        .finally((_) => {
          this.mproLoading = false;
        });
    },
    updateProductRecords() {
      this.mproLoading = true;
      axios
        .get(
          `${this.$store.getters.getEnvironment}integration/mpro/sync/products`
        )
        .then((x) => {
          this.showSnackbar = true;
        })
        .catch((e) => {
          if (e.response.status == 409 || e.response.status == 403) {
            this.showConflictSnackbar = true;
          }
        })
        .finally((_) => {
          this.mproLoading = false;
        });
    },

    download(blob, filename, applicationType) {
      blob = new Blob([blob], { type: applicationType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    exportUtilization() {
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}utilization/export`,
        { responseType: "blob" }
      );

      return xhr
        .then((res) => {
          this.download(res.data, "utilization.csv", "application/csv");
        })
        .catch((e) => {});
    },
  },
  created() {
    //Lead commerce
    axios
      .get(
        `${this.$store.getters.getEnvironment}integration/leadCommerce/sync/lastUpdateDate`
      )
      .then((res) => {
        if (res.data != null && res.data.trim()) {
          this.lcLastSyncDate = new Date(res.data);
        } else {
          this.lcLastSyncDate = null;
        }
      })
      .catch((err) => {});

    //Odoo
    axios
      .get(`${this.$store.getters.getEnvironment}odoo/LastSynchronizationDate`)
      .then((res) => {
        if (res.data != null && res.data.trim()) {
          this.odooLastSyncDate = new Date(res.data);
        } else {
          this.odooLastSyncDate = null;
        }
      })
      .catch((err) => {});
  },
};
</script>
