var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-2",attrs:{"elevation":"1","tile":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("account.integrations.mpro")))])]),_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updatePatientRecord}},[(!_vm.mproLoading)?[_vm._v(" Update MPro Patient records ")]:_vm._e(),(_vm.mproLoading)?[_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})]:_vm._e()],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updateProductRecords}},[(!_vm.mproLoading)?[_vm._v("Update MPro Product records")]:_vm._e(),(_vm.mproLoading)?[_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})]:_vm._e()],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.syncMproUtilizations()}}},[(!_vm.mproLoading)?[_vm._v(" "+_vm._s(_vm.$t( "account.integration.mproIntegration.importLatestUtilizations" ))+" ")]:_vm._e(),(_vm.mproLoading)?[_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})]:_vm._e()],2),_c('h3',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(_vm.$t( "account.integration.mproIntegration.utilizationsImportLog" ))+" ")]),_c('v-divider'),_c('v-data-table',{attrs:{"dense":"","loading":_vm.mproUtilizationLogLoading,"items":_vm.mproUtilizationLogs,"headers":_vm.mproUtilizationLogsHeaders,"sort-by":['fromDate'],"sort-desc":true,"item-key":"updateDate"},scopedSlots:_vm._u([{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.fromDate,"YYYY-MM-DD")))]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.toDate,"YYYY-MM-DD")))]}},{key:"item.isSuccess",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"x-small":"","color":item.nbErrors == 0 ? 'success' : 'error'}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-blank-circle")])]}}],null,true)},[(item.nbErrors > 0)?_c('span',[_vm._v(_vm._s(_vm.$t("account.integration.mproIntegration.hasError")))]):_c('span',[_vm._v(_vm._s(_vm.$t("account.integration.mproIntegration.isOk")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.wasRerun)?_c('v-icon',_vm._g(_vm._b({staticClass:"pl-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-recycle-variant")]):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("account.integration.mproIntegration.wasRerun", { rerunDate: _vm.$moment(item.updateDate).format( "YYYY-MM-DD" ), }))+" ")])]}},{key:"item.downloadLogsAction",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","icon":"","loading":_vm.mproUtilizationLogDownloading,"disabled":_vm.mproLoading},on:{"click":function($event){return _vm.downloadLogForUtilization(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}},{key:"item.rerunImportAction",fn:function(ref){
var item = ref.item;
return [(item.nbErrors > 0)?_c('v-btn',{attrs:{"depressed":"","icon":"","disabled":_vm.mproLoading},on:{"click":function($event){return _vm.syncMproUtilizations(item)}}},[_c('v-icon',[_vm._v("mdi-recycle-variant")])],1):_vm._e()]}}])})],1)],1)],1)],1)],1),_c('v-snackbar',{staticStyle:{"top":"8px"},attrs:{"top":"","color":_vm.showSnackbarColor,"timeout":3000},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.$t("account.actions.syncronized"))+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("CLOSE")])],1),_c('v-snackbar',{staticStyle:{"top":"8px"},attrs:{"top":"","color":"red darken-2","timeout":3000},model:{value:(_vm.showConflictSnackbar),callback:function ($$v) {_vm.showConflictSnackbar=$$v},expression:"showConflictSnackbar"}},[_vm._v(" "+_vm._s(_vm.$t("account.actions.syncBeingDone"))+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showConflictSnackbar = false}}},[_vm._v("CLOSE")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }