<template>
  <v-app-bar class="white" elevation="0" height="170">
    <v-img
      :src="imgHuman"
      max-height="60"
      max-width="250"
      contain
      position="left"
      class="pr-1"
    ></v-img>
    <v-spacer />
    <h1>{{ $t("app.title") }}</h1>
    <v-spacer />
    <v-btn text class="black--text" @click="$i18n.locale = 'en'">EN</v-btn>
    <v-btn text class="black--text" @click="$i18n.locale = 'fr'">FR</v-btn>
    <v-btn text class="black--text" @click="$i18n.locale = 'es'">ES</v-btn>
    <v-btn text class="black--text" @click="$i18n.locale = 'ru'">RU</v-btn>

    <v-snackbar
      v-model="isShowSnackBarVisible"
      top
      :color="this.$store.state.showSnackbar.color"
      style="top: 8px"
      timeout="3000"
    >
      {{ this.$store.state.showSnackbar.text }}
      <v-btn color="white" text @click="isShowSnackBarVisible = false">X</v-btn>
    </v-snackbar>
  </v-app-bar>
</template>

<script>
import imgHuman from "../assets/humaidlogo.png";
export default {
  data() {
    return {
      imgHuman,
      isShowSnackBarVisible: false,
    };
  },
  methods: {},
  computed: {
    isShowSnackBar() {
      return this.$store.state.showSnackbar.key;
    },
  },
  watch: {
    isShowSnackBar() {
      this.isShowSnackBarVisible = true;
    },
  },
};
</script>
