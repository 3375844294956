<template>
  <div>
    <v-app-bar
      class="white"
      elevation="0"
      height="80"
      min-height="80"
      max-height="80"
    >
      <v-img
        :src="imgHuman"
        height="80"
        width="125"
        contain
        max-width="125"
      ></v-img>
      <v-spacer />
      <h1 class="px-5 ellipsis d-none d-lg-block">{{ $t("app.title") }}</h1>
      <h2 class="px-5 ellipsis d-lg-none">{{ $t("app.title") }}</h2>
      <v-spacer />
      <v-btn text class="black--text" @click="changeLanguage('en')">EN</v-btn>
      <v-btn text class="black--text" @click="changeLanguage('fr')">FR</v-btn>
      <v-btn text class="black--text" @click="changeLanguage('es')">ES</v-btn>
      <v-btn text class="black--text" @click="changeLanguage('ru')">RU</v-btn>
    </v-app-bar>
    <v-app-bar elevation="0" color="#999898" height="45">
      <v-btn
        v-if="this.$store.getters.getIsAdmin"
        text
        :class="getClass('NMOS')"
        @click="changePage('NMOS')"
        >{{ $t("app.navBar.nmos") }}</v-btn
      >
      <v-btn
        v-if="this.$store.getters.getIsAdmin"
        text
        :class="getClass('HTCS')"
        @click="changePage('HTCS')"
        >{{ $t("app.navBar.htcs") }}</v-btn
      >
      <v-btn
        v-if="
          this.$store.getters.getIsNMOAdmin || this.$store.getters.getIsAdmin
        "
        text
        :class="getClass('LotNumbers')"
        @click="changePage('LotNumbers')"
        >{{ $t("app.navBar.lotNumbers") }}</v-btn
      >
      <v-btn
        v-if="this.$store.getters.getIsAdmin"
        text
        :class="getClass('Products')"
        @click="changePage('Products')"
        >{{ $t("app.navBar.products") }}</v-btn
      >
      <v-btn
        text
        :class="getClass('Patients')"
        @click="changePage('Patients')"
        >{{ $t("app.navBar.patients") }}</v-btn
      >
      <v-btn
        text
        :class="getClass('Utilization')"
        @click="changePage('Utilization')"
        >{{ $t("app.navBar.utilization") }}</v-btn
      >
      <!-- <v-btn
        v-if="
          this.$store.getters.getIsNMOAdmin || this.$store.getters.getIsAdmin
        "
        text
        :class="getClass('InventoryAudit')"
        @click="changePage('InventoryAudit')"
        >{{ $t("app.navBar.inventoryAudit") }}</v-btn
      > -->
      <v-spacer></v-spacer>
      <v-menu bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon class="white--text">mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-card width="300" class="elevation-0">
            <p class="subtitle-2 text-center mb-0">
              <v-card-text>
                <v-icon x-large :color="this.$store.state.loadingBarColor"
                  >mdi-account-circle-outline</v-icon
                >
                <br />
                {{ $store.state.connectedUser.name }}
                <br />
                {{ $store.state.connectedUser.email }}
              </v-card-text>
            </p>
            <v-divider></v-divider>
            <p class="mt-4 mx-10">
              <v-btn
                class="green--text"
                text
                block
                @click="changePage('ChangePassword')"
                style="cursor: pointer"
                >{{ $t("app.navBar.changePassword") }}</v-btn
              >
              <v-btn
                v-if="this.$store.getters.getIsAdmin"
                class="green--text"
                text
                block
                @click="changePage('Users')"
                style="cursor: pointer"
                >{{ $t("app.navBar.users") }}</v-btn
              >
              <v-btn
                v-if="this.$store.getters.getIsAdmin"
                class="green--text"
                text
                block
                @click="changePage('ControlPanel')"
                style="cursor: pointer"
                >{{ $t("app.navBar.administration") }}</v-btn
              >
            </p>
            <v-divider></v-divider>
            <p class="text-center mt-5 mx-10">
              <v-btn block class="red white--text" @click="signOut()">
                {{ $t("app.navBar.signout") }}
              </v-btn>
            </p>
          </v-card>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-snackbar
      v-model="isShowSnackBarVisible"
      top
      :color="this.$store.state.showSnackbar.color"
      style="top: 8px"
      timeout="3000"
    >
      {{ this.$store.state.showSnackbar.text }}
      <v-btn color="white" text @click="isShowSnackBarVisible = false">X</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import imgHuman from "../../assets/humaidlogo.png";
export default {
  data() {
    return {
      imgHuman,
      classButtonNMOS: "white--text",
      isShowSnackBarVisible: false,
    };
  },
  methods: {
    changePage(page) {
      this.$router.push({ name: page }).catch(() => {});
      this.$store.commit("setCurrentPage", page);
    },
    changeLanguage(languageCode) {
      this.$i18n.locale = languageCode;
      this.$store.commit("setLanguageKey", null);
      this.$store.commit("setConnectdUserLang", languageCode);
      var xhr = axios.patch(`${this.$store.getters.getEnvironment}user/me`, {
        languageId: this.$store.getters.getLangId,
      });
      xhr.then((res) => {});
    },
    getClass(page) {
      if (page == this.$store.state.currentPage) {
        return "black--text";
      } else {
        return "white--text";
      }
    },
    signOut() {
      sessionStorage.removeItem("token");
      localStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
  },
  computed: {
    isShowSnackBar() {
      return this.$store.state.showSnackbar.key;
    },
  },
  watch: {
    isShowSnackBar() {
      this.isShowSnackBarVisible = true;
    },
  },
};
</script>
