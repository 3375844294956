<template>
  <span>
    <!-- <v-dialog v-model="showDialog" :width="width" :max-width="maxwidth" persistent fullscreen>
      <component
        :is="dialogName"
        :parameter="parameter"
        :width="width"
        :maxwidth="maxwidth"
      />
    </v-dialog> -->

    <v-dialog
      v-model="showDialog"
      :width="modalSize"
      :max-width="modalSize"
      scrollable
    >
      <v-card>
        <v-card-title class="d-flex pa-2 pl-5" style="background: grey">
          <span class="white--text">{{ title }}</span>
          <v-spacer />
          <v-btn fab small dark color="grey" @click="showDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-col>
            <component
              @onSetTitle="onSetTitle"
              :is="dialogName"
              :parameter="parameter"
            />
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>


<script>
export default {
  name: "dialog-handler-second",
  data() {
    return {
      stateTitle: "",
    };
  },
  methods: {
    onSetTitle(title) {
      this.stateTitle = title;
    },
  },
  components: {},
  computed: {
    title() {
      return this.stateTitle;
    },
    showDialog: {
      get() {
        return this.$store.state.modalSeconds.length > 0;
      },
      set(v) {
        if (v == true) this.$store.commit("showModalSecond", null);
        else this.$store.commit("hideModalSecond", null);
      },
    },
    dialogName() {
      if (this.$store.state.modalSeconds.length > 0)
        return this.$store.state.modalSeconds[
          this.$store.state.modalSeconds.length - 1
        ].component;
      return "";
    },
    parameter() {
      if (this.$store.state.modalSeconds.length > 0)
        return this.$store.state.modalSeconds[
          this.$store.state.modalSeconds.length - 1
        ].parameter;
      return "";
    },
    modalSize() {
      return this.$store.state.modalOptions.secondSize;
    },
    width() {
      if (this.$store.state.modalSeconds.length > 0)
        return this.$store.state.modalSeconds[
          this.$store.state.modalSeconds.length - 1
        ].width;
      return "";
    },
    maxwidth() {
      if (this.$store.state.modalSeconds.length > 0)
        return this.$store.state.modalSeconds[
          this.$store.state.modalSeconds.length - 1
        ].maxwidth;
      return "";
    },
  },
};
</script>

