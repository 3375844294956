<template>
  <v-app>
    <div id="app" @contextmenu="hideContext($event)">
      <router-view name="navigation"></router-view>
      <router-view />
      <dialog-handler />
      <dialog-handler-second />
    </div>
  </v-app>
</template>

<script>
import DialogHandler from "./components/DialogHandler.vue";
import DialogHandlerSecond from "./components/DialogHandlerSecond.vue";
export default {
  name: "home",
  components: {
    DialogHandler,
    DialogHandlerSecond,
  },
  methods: {
    hideContext(e) {
      if (process.env.PRODUCTION) e.preventDefault();
    },
  },
};
</script>

