 <template>
  <v-card elevation="0" v-if="isAdmin">
    <v-card-text>
      <v-tabs
        v-model="currentTab"
        center-active
        active-class="active-tab"
        class="tabs pt-3"
        height="30"
      >
        <v-tab class="tab" key="integration" href="#integration">
          {{ $t("account.controlPanel.tabs.integration") }}
        </v-tab>
        <v-tab class="tab" key="lotStatusConfig">{{
          $t("account.controlPanel.tabs.lotStatus")
        }}</v-tab>
        <v-tab class="tab" key="TranslationMapping">{{
          $t("account.controlPanel.tabs.translationMapping")
        }}</v-tab>
      </v-tabs>
      <v-divider />
      <v-tabs-items v-model="currentTab">
        <v-tab-item value="integration" key="integration">
          <integration-tab></integration-tab>
        </v-tab-item>
        <v-tab-item key="lotStatusConfig">
          <lot-status-configuration></lot-status-configuration>
        </v-tab-item>
        <v-tab-item key="TranslationMapping">
          <translation-mapping-configuration></translation-mapping-configuration>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import IntegrationTab from "../controlPanel/Integration";
import LotStatusConfiguration from "../controlPanel/LotStatusConfiguration";
import TranslationMappingConfiguration from "../controlPanel/TranslationMappingConfiguration.vue";
export default {
  components: {
    IntegrationTab,
    LotStatusConfiguration,
    TranslationMappingConfiguration,
  },
  data() {
    return {
      currentTab: "integration",
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },
  },
};
</script>

