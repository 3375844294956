<template>
  <div>
    <v-card tile elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="5" class="d-flex">
            <v-text-field
              dense
              hide-details=""
              v-model="search"
              :label="$t('actions.filters')"
              append-icon="mdi-magnify"
            ></v-text-field
          ></v-col>
          <v-col cols="1" class="d-flex">
            <v-btn color="blue" text small class="white--text" @click="load()">
              <v-icon>mdi-refresh</v-icon>
              {{ $t("actions.refresh") }}
            </v-btn></v-col
          >
          <v-col cols="6" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn text tile small class="green--text" @click="openDetail(-1)">
              <v-icon>mdi-plus</v-icon>
              {{ $t("actions.add") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :key="this.$store.state.userKey"
          :items="datas"
          :sort-by="['name']"
          :loading="isDataLoading"
          :loading-text="$t('app.loading')"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
          :items-per-page="this.$store.state.itemsPerPage"
          :search="search"
          dense
        >
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="openDetail(item.id)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.nmoCountryCode="{ item }">
            <span v-if="item.nmoCountryCode != null">
              {{ $t("country." + item.nmoCountryCode) }}
            </span>
          </template>
          <template v-slot:item.share="{ item }">
            <v-checkbox v-model="item.share" readonly disabled></v-checkbox>
          </template>
          <template v-slot:item.creationDate="{ item }">
            <span v-if="item.creationDate != null">{{
              item.creationDate | moment("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </template>
          <template v-slot:item.activationDate="{ item }">
            <span v-if="item.activationDate != null">{{
              item.activationDate | moment("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </template>
          <template v-slot:item.hasMobileAccess="{ item }">
            <v-btn
              tile
              icon
              @click="
                updateUserPartially({
                  id: item.id,
                  hasMobileAccess: !item.hasMobileAccess,
                })
              "
            >
              <v-icon dense :color="item.hasMobileAccess ? 'green' : 'red'">
                {{ item.hasMobileAccess ? "mdi-check" : "mdi-cancel" }}
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.isActive="{ item }">
            <v-btn
              tile
              icon
              @click="
                updateUserPartially({ id: item.id, isActive: !item.isActive })
              "
            >
              <v-icon dense :color="item.isActive ? 'green' : 'red'">
                {{ item.isActive ? "mdi-check" : "mdi-cancel" }}
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.canDeleteUtilization="{ item }">
            <v-btn
              tile
              icon
              @click="
                updateUserPartially({
                  id: item.id,
                  canDeleteUtilization: !item.canDeleteUtilization,
                })
              "
            >
              <v-icon
                dense
                :color="item.canDeleteUtilization ? 'green' : 'red'"
              >
                {{ item.canDeleteUtilization ? "mdi-check" : "mdi-cancel" }}
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" light color="green">
      <span class="white--text">
        {{ $t("user.message.updated") }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
          >X</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      isDataLoading: false,
      datas: [],
      search: "",
      snackbar: false,
      headers: [
        {
          text: this.$t("htc.headerRow.action"),
          value: "action",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.firstName"),
          value: "firstName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.lastName"),
          value: "lastName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.email"),
          value: "email",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.username"),
          value: "username",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.phoneNumber"),
          value: "phoneNumber",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.type"),
          value: "userTypeName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.nmoName"),
          value: "nmoName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.nmoCountry"),
          value: "nmoCountryCode",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.htcName"),
          value: "htcName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.creationDate"),
          value: "creationDate",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.activationDate"),
          value: "activationDate",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.isActive"),
          value: "isActive",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.hasMobileAccess"),
          value: "hasMobileAccess",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.canDeleteUtilization"),
          value: "canDeleteUtilization",
          class: "grey white--text",
        },
        // {
        //   text: this.$t("user.headerRow.notes"),
        //   value: "notes",
        //   class: "grey white--text",
        // },
      ],
    };
  },
  computed: {
    refreshFlag() {
      return this.$store.state.userKey;
    },
  },
  watch: {
    refreshFlag() {
      this.load();
    },
  },
  methods: {
    load() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(this.$store.getters.getEnvironment + "user?isActive=")
        .then((response) => {
          this.datas = response.data;
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    openDetail(value) {
      this.$store.commit("showModal", {
        componentName: "UserDetail",
        parameter: { id: value },
      });
    },
    updateUserPartially(param) {
      var id = param.id;
      delete param.id;
      axios
        .patch(`${this.$store.getters.getEnvironment}user/${id}`, param)
        .then((res) => {
          var index = this.datas.findIndex((x) => x.id == id);
          var key = Object.keys(param)[0];
          this.datas[index][key] = !param[key];
          this.snackbar = true;
          this.load();
        });
    },
  },
  created() {
    this.load();
  },
};
</script>
